/*
Acts as a middleware between API functions and GET functions, caches response, and returns whichever
is most appropriate depending on current online status. Generally, API results if online, and cached data if
offline.

key: The key for cached value in localStorage
url: The URL for the request
credentials: Credentials to auth the request
validationFunc:  As different API functions are a bit inconsistant in how they send errors, this allows us to 
accomodate for that.

- Fire an AJAX request and get data
- Upon receipt of data cache it in localstorage,
- If AJAX fails, check for cache in localstorage and return that if found
- If no cache found, reject promise
*/

import post from './post';
import { storeLocalStorage, parseLocalStorage } from './handleStorage';

function handleKey(key, value) {
	if(typeof key === 'object') {
		if(localStorage.getItem(key.key)) {
			const obj = parseLocalStorage(key.key);
			obj[key.subKey] = value;
			storeLocalStorage(key['key'], obj);
		}
		else {
			storeLocalStorage(key['key'], {
				[key.subKey] : value
			})
		}
	}
	else {
		storeLocalStorage(key, value);
	}
}

function getKey(key) {
	if(typeof key === 'object') {
		if(localStorage.getItem(key.key)) {
			const obj = parseLocalStorage(key.key)
			return obj[key.subKey];
		}
	}
	else {
		return parseLocalStorage(key);
	}
}

function getData(key, url, credentials, validationFunc) {
	return new Promise((resolve, reject) => { 
		post(url, {})
		.then(response => {
			if(validationFunc) {
				if(validationFunc(response)) {
					handleKey(key, response);
					resolve(response);
				}
				else {
					reject("Bad")
				}
			}
			else {
				handleKey(key, response);
				resolve(response);
			}
		})
		.catch(error => {
			if(getKey(key)) {
				resolve(getKey(key))
			}
			else {
				reject(error);
			}
		})
	})
}

export default getData;
