import React from 'react';
import PropTypes from 'prop-types';

const MediaCollection = (props) => {
	return (
		<div className={`${props.className} c-media-collection ${props.disableBorder ? 'c-media-collection--disable-border' : null}`}>
			{props.title ? (
				<div className={`c-media-collection__title u-align-center u-margin-bottom-regular ${props.description ? 'u-margin-bottom-large@md' : null}`}>
					<h2 className={`c-type-bravo ${props.description ? 'u-margin-bottom-small' : null}`}>{props.title}</h2>
					{props.description ? (
						<p className="c-type-foxtrot">{props.description}</p>
					) : null}
				</div>
			) : null}
			<div className="o-layout o-layout--spacing-large">
				{props.children}
			</div>
		</div>
	)
}

MediaCollection.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	disableBorder: PropTypes.bool,
	className: PropTypes.string
}

export default MediaCollection;