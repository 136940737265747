import React from 'react';
import PropTypes from 'prop-types';

class HamburgerButton extends React.Component {

	static propTypes = {
		handleClick: PropTypes.func,
		active: PropTypes.bool
	};

	render() {
		return (
			<button
				className={`c-button c-button--secondary c-hamburger-button ${this.props.active ? 'is-active' : ''}`}
				tabIndex="0"
				onClick={this.props.handleClick}
			>
				<span className="c-hamburger-button__text">{this.props.active ? 'Close' : 'Menu'}</span>
				<div className="c-hamburger-button__lines">
					<span className="c-hamburger-button__line c-hamburger-button__line--1"></span>
					<span className="c-hamburger-button__line c-hamburger-button__line--2"></span>
					<span className="c-hamburger-button__line c-hamburger-button__line--3"></span>
				</div>
			</button>
		);
	}
}

export default HamburgerButton;