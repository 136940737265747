import React from 'react';
import PropTypes from 'prop-types';

import StatusMsg from './StatusMsg';

class Loading extends React.Component {
	static propTypes = {
		color: PropTypes.string,
		fullScreen: PropTypes.bool,
		timeout: PropTypes.bool
	};

	static defaultProps = {
		timeout: true
	}

	constructor(props) {
		super(props);
		this.state = {
			showTimeoutMessage: false,
		}
	}

	componentDidMount() {
		if(this.props.timeout) {
			window.amTimeout = setTimeout(() => {
				this.setState({
					showTimeoutMessage: true
				})
			}, 5000)
		}
	}

	componentWillUnmount() {
		clearTimeout(window.amTimeout);
	}

	render() {
		const loading = (
			<div className={`c-loading c-loading--${this.props.color}`}>
				<div className='c-loading__inner'>
					<div className="c-loading__circle--1 c-loading__circle"></div>
					<div className="c-loading__circle--2 c-loading__circle"></div>
					<div className="c-loading__circle--3 c-loading__circle"></div>
					<div className="c-loading__circle--4 c-loading__circle"></div>
					<div className="c-loading__circle--5 c-loading__circle"></div>
					<div className="c-loading__circle--6 c-loading__circle"></div>
					<div className="c-loading__circle--7 c-loading__circle"></div>
					<div className="c-loading__circle--8 c-loading__circle"></div>
					<div className="c-loading__circle--9 c-loading__circle"></div>
					<div className="c-loading__circle--10 c-loading__circle"></div>
					<div className="c-loading__circle--11 c-loading__circle"></div>
					<div className="c-loading__circle--12 c-loading__circle"></div>
				</div>
			</div>
		);

		const message = (
			<React.Fragment>
				<h2 className="c-type-alpha u-margin-bottom-small">Oops!</h2>
				<p>There seems to have been a problem. Please try again and if the problem persists contact development, communicating the steps taken to trigger it.</p>
			</React.Fragment>
		);

		if(this.props.fullScreen) {
			return (
				<StatusMsg>
					{this.state.showTimeoutMessage ? message : loading}
				</StatusMsg>
			)
		}
		else {
			return this.state.showTimeoutMessage ? message : loading;
		}
	}
}

export default Loading;
