import React from 'react';

import StyledBox from './StyledBox';
import Input from './Input';
import Tooltip from './Tooltip';
import Switch from './Switch';
import Expander from './Expander';

class DealerNotes extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			active: true
		}
	}

	toggleActive = () => {
		this.setState({
			active: !this.state.active
		})
	}

	render() {
		if(this.props.notes) {
			return (
				<React.Fragment>
					<div className="o-layout o-layout--flush o-layout--middle u-margin-bottom-small">
						<div className="o-layout__item u-width-shrink">
							<h2 className="c-type-delta u-margin-right-tiny">Notes</h2>
						</div>
						<div className="o-layout__item u-width-shrink">
							<Tooltip
								small
								message="Notes are read-only and pulled from Evolve. The switch to the right toggles note visability."
							/>
						</div>
						<div className="o-layout__item u-align-right u-width-grow">
							<Switch active={this.state.active} handleClick={this.toggleActive} />
						</div>
					</div>
					<Expander active={this.state.active}>
						<StyledBox>
							<div className="o-box o-box--spacing-small">
								<Input name="notes" type="textarea" disabledInvisible={true} rows="10" value={this.props.notes} standardTextColor />
							</div>
						</StyledBox>
					</Expander>
				</React.Fragment>
			);
		}
		else return null;
	}

}

export default DealerNotes;