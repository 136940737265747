import React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';
import Form from './Form';
import Input from './Input';
import Switch from './Switch';

import { addDealer } from './../api/dealerWrite';

import configData from '../settings.js';

class AddDealerForm extends React.Component {
	static propTypes = {
		active: PropTypes.bool,
		handleClose: PropTypes.func,
		franchiseTypes: PropTypes.object,
		onDealerAdd: PropTypes.func,
		online: PropTypes.bool
	};

	defaultState = {
		loading: false,
		success: null,
		errorMsg: null,
		dealer: {
			name: '',
			legalName: '',
			postcode: '',
			email: '',
			fax: '',
			contact: '',
			password: '',
			companyNumber: '',
			vat: '',
			franchise: '',
			creditBrokerage: false,
			debtCounselling: false,
			debtAdjusting: false,
			iceId: null
		}
	}

	constructor(props) {
		super(props);
		this.state = this.defaultState;
	}

	restoreInputs = () => {
		this.setState({
			success: null,
			errorMsg: ''
		})
	}

	submitNewDealer = () => {
		this.setState({
			loading: true
		});
		const newDealer = {...this.state.dealer};
		newDealer.iceId = `temp-${Math.floor(100000 + Math.random() * 900000)}`
		
		addDealer(this.props.credentials, newDealer)
		.then((response) => {
			// If duplicate
			if(response === 'Dealer already exists with entered name.') {
				this.setState({
					success: false,
					loading: false,
					errorMsg: 'A dealer already exists with that name, please use a different one.'
				})
			}
			else {
				const newState = {...this.state}
				newState.dealer.iceId = response;
				newState.success = true;
				this.setState(newState, () => {
					this.props.onDealerAdd(this.state.dealer)
				})
			}		
		})
		.catch((error) => {
			this.props.onDealerAdd(newDealer, true);
			this.setState({
				success: true
			})
		})
	}

	componentDidUpdate(prevProps) {
		if(prevProps.active && !this.props.active) {
			this.setState(this.defaultState)
		}
	}

	handleClose = (prompt) => {
		if(prompt) {
			if(window.confirm("Are you sure you wish to cancel?")) {
				this.props.handleClose();
			}
		}
		else {
			this.props.handleClose();
		}
	}

	updateDealerState = (state, value) => {
		const dealer = {...this.state.dealer};
		dealer[state] = value;
		this.setState({
			dealer : dealer
		})
	}

	render() {
		if(this.props.active) {
			return (
				<Modal title="Add Dealer" handleClose={() => this.handleClose(this.state.success === null)} showCloseButton={false} showCloseIcon={!this.state.loading}>
					<Form
						handleSubmit={this.submitNewDealer}
						handleCancel={() => this.handleClose(this.state.success === null)}
						handleReset={this.restoreInputs}
						loading={this.state.loading}
						success={this.state.success}
						failure={this.state.success === false}
						item="dealer"
						errorMsg={!this.state.success ? this.state.errorMsg : ''}
					>
						{this.props.online ? (
							<div className="o-box o-box--spacing-small c-box c-box--radius u-theme-primary u-text-white u-margin-bottom-regular c-alphabet">
								<p><b>Did you know?</b> You can now submit a full dealer setup pack with instant grading. <a className="c-type-link u-nowrap u-text-white" href={`${configData.EVOLUTION_MAIN_ENDPOINT}/dealer-info/setup/`}>Click here</a> to get started.</p>
							</div>
						) : null}
						<div className="o-layout o-layout--spacing-large">
							<div className="o-layout__item u-width-12/12 u-width-6/12@sm3">
								<Input label="Name*" className="u-margin-bottom-small" name="name" required valueMissingMsg="Please enter the dealer name" handleChange={(value) => this.updateDealerState('name', value)} value={this.state.dealer.name} />
								<Input label="Legal Name" className="u-margin-bottom-small" name="legal-name" handleChange={(value) => this.updateDealerState('legalName', value)} value={this.state.dealer.legalName} />
								<Input label="Post Code*" className="u-margin-bottom-small" name="postcode" required valueMissingMsg="Please enter a post code" handleChange={(value) => this.updateDealerState('postcode', value)} value={this.state.dealer.postCode} />
								<Input label="Email Address" className="u-margin-bottom-small" name="email" type="email" typeMismatchMsg="Please enter a valid email address" handleChange={(value) => this.updateDealerState('email', value)} value={this.state.dealer.email} />
								<Input label="Fax" className="u-margin-bottom-small u-margin-bottom-regular@sm3" name="fax" handleChange={(value) => this.updateDealerState('fax', value)} value={this.state.dealer.fax} />
							</div>
							<div className="o-layout__item u-width-12/12 u-width-6/12@sm3">
								<Input label="Contact Number*" className="u-margin-bottom-small" name="phone" type="tel" pattern="^(01|02|03|07)[0-9]{9}" required patternMismatchMsg="Please enter a contact number with 11 numbers which begins with either 01, 02, 03 or 07" valueMissingMsg="Please enter a contact number" handleChange={(value) => this.updateDealerState('contact', value)} value={this.state.dealer.contactNumber} />
								<Input label="Password*" className="u-margin-bottom-small" name="password" required valueMissingMsg="Please enter a password" handleChange={(value) => this.updateDealerState('password', value)} value={this.state.dealer.password} />
								<Input label="Company Number" className="u-margin-bottom-small" name="company-number" handleChange={(value) => this.updateDealerState('companyNumber', value)} value={this.state.dealer.companyNumber} />
								<Input label="VAT Number" className="u-margin-bottom-small" name="vat" handleChange={(value) => this.updateDealerState('vat', value)} value={this.state.dealer.vat} />
								{this.props.franchiseTypes ? (
									<Input label="Franchise*" type="select" className="u-margin-bottom-small u-margin-bottom-regular@sm3" name="franchise" required valueMissingMsg="Please enter a franchise" handleChange={(value) => this.updateDealerState('franchise', value)} value={this.state.dealer.franchise}>
										<React.Fragment>
											<option value=""></option>
											{Object.keys(this.props.franchiseTypes).map((key, i) => {
												return <option key={i} value={this.props.franchiseTypes[key]}>{key}</option>;
											})}
										</React.Fragment>
									</Input>
								) : null}
							</div>
							<div className="o-layout__item u-width-12/12 u-width-4/12@sm3 u-margin-bottom-small u-margin-bottom-none@sm3">
								<Switch label="Credit Brokerage" active={this.state.dealer.creditBrokerage} handleClick={() => this.updateDealerState('creditBrokerage', !this.state.dealer.creditBrokerage)} />
							</div>
							<div className="o-layout__item u-width-12/12 u-width-4/12@sm3 u-margin-bottom-small u-margin-bottom-none@sm3">
								<Switch label="Debt Counselling" active={this.state.dealer.debtCounselling} handleClick={() => this.updateDealerState('debtCounselling', !this.state.dealer.debtCounselling)} />
							</div>
							<div className="o-layout__item u-width-12/12 u-width-4/12@sm3 u-margin-bottom-small u-margin-bottom-none@sm3">
								<Switch label="Debt Adjusting" active={this.state.dealer.debtAdjusting} handleClick={() => this.updateDealerState('debtAdjusting', !this.state.dealer.debtAdjusting)} />
							</div>
						</div>
					</Form>
				</Modal>
			);
		}
		else return null;
	}
}

export default AddDealerForm
