import React from 'react';
import PropTypes from 'prop-types';

const StyledBox = (props) => {
	return (
		<React.Fragment>
			{props.title ? <h2 className="c-type-delta u-margin-bottom-small">{props.title}</h2> : null}
			<div className={`c-box c-box--white c-box--rounded c-box--shadow c-styled-box u-margin-bottom-regular ${!props.overflow ? 'c-box--no-overflow' : ''}`}>
				{props.children}
			</div>
		</React.Fragment>
	)
}

StyledBox.propTypes = {
	title: PropTypes.string,
	overflow: PropTypes.bool
}

export default StyledBox;