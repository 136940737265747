import React from 'react';
import PropTypes from 'prop-types';

import options from './../data/letterOptions';

class Alphabet extends React.Component {
	static propTypes = {
		activeLetter: PropTypes.string,
		disabledLetters: PropTypes.array,
		handleClick: PropTypes.func
	};

	handleClick = (letter, disabled) => {
		if(!disabled) {
			this.props.handleClick(letter);
		}
	}

	printOption = (option, i, props) => {
		const isDisabled = this.props.disabledLetters.includes(option);
		const active = this.props.activeLetter === option;
		const letter = () => <button className={`o-layout__item c-alphabet__letter ${active ? 'is-active' : ''} ${isDisabled ? 'is-disabled' : ''}`} key={i} tabIndex="0" onClick={() => this.handleClick(option, isDisabled)}>{option}</button>;
		if(i % 9 === 0 && i !== 0) {
			return (
				<React.Fragment key={i}>
					<div className="o-layout__item o-layout__item--break u-hide@max-md"></div>
					{letter()}
				</React.Fragment>
			)
		}
		else return letter();
	}

	render() {
		return (
			<div className="c-alphabet">
				<div className="o-layout c-alphabet__container">
					{options.map((option, i) => {
						return this.printOption(option, i, this.props);
					})}
				</div>
			</div>
		);
	}
}

export default Alphabet;