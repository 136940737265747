import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';
import Input from './Input';
import Button from './Button';

import ValuesCoop from './../assets/img/values-cooperative.png';
import ValuesCoop2x from './../assets/img/values-cooperative@2x.png';
import ValuesCustomers from './../assets/img/values-customers.png';
import ValuesCustomers2x from './../assets/img/values-customers@2x.png';
import ValuesExcellent from './../assets/img/values-excellent.png';
import ValuesExcellent2x from './../assets/img/values-excellent@2x.png';
import ValuesFuelGrowth from './../assets/img/values-fuel-growth.png';
import ValuesFuelGrowth2x from './../assets/img/values-fuel-growth@2x.png';
import ValuesLeadChange from './../assets/img/values-lead-change.png';
import ValuesLeadChange2x from './../assets/img/values-lead-change@2x.png';
import ValuesSpreadOptimism from './../assets/img/values-spread-optimism.png';
import ValuesSpreadOptimism2x from './../assets/img/values-spread-optimism@2x.png';
import ValuesWorkFearlessly from './../assets/img/values-work-fearlessly.png';
import ValuesWorkFearlessly2x from './../assets/img/values-work-fearlessly@2x.png';

class Login extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		handleSubmit: PropTypes.func,
		error: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			emailAddress: '',
			passwordSubmit: false
		}
		this.form = React.createRef();

		switch(Math.floor(Math.random() * 7) + 1) {
			case 1:
				this.state.randomBanner = ValuesCoop;
				this.state.randomBanner2x = ValuesCoop2x;
				break;
			case 2:
				this.state.randomBanner = ValuesCustomers;
				this.state.randomBanner2x = ValuesCustomers2x;
				break;
			case 3:
				this.state.randomBanner = ValuesExcellent;
				this.state.randomBanner2x = ValuesExcellent2x;
				break;
			case 4:
				this.state.randomBanner = ValuesFuelGrowth;
				this.state.randomBanner2x = ValuesFuelGrowth2x;
				break;
			case 5:
				this.state.randomBanner = ValuesLeadChange;
				this.state.randomBanner2x = ValuesLeadChange2x;
				break;
			case 6:
				this.state.randomBanner = ValuesSpreadOptimism;
				this.state.randomBanner2x = ValuesSpreadOptimism2x;
				break;
			case 7:
				this.state.randomBanner = ValuesWorkFearlessly;
				this.state.randomBanner2x = ValuesWorkFearlessly2x;
				break;
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.handleSubmit();
	}

	updateState = (state, value) => {
		this.setState({
			[state] : value
		})
	}

	render() {
		const columnFlexContainer = {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			height: "100%",
			justifyContent: "center"
		};
		return (
			<form className={`c-login ${this.props.error ? 'is-error-login' : ''} ${this.state.loading ? 'is-loading' : ''}`} onSubmit={(e) => this.handleSubmit(e)} ref={this.form}>
				<Icon use="logo" className="c-login__logo u-margin-bottom-regular" />
				<h1 className="c-type-delta u-margin-bottom-small u-margin-bottom-regular@md u-align-center">Account Manager Dashboard</h1>
				<div className="o-layout o-layout--flush o-layout--fit-height c-login__layout">
					<div className="o-layout__item c-box--white u-width-12/12 u-width-6/12@md3">
						<div className="o-box--spacing-regular" style={columnFlexContainer}>
							<div className="u-margin-bottom-small">
								<Button type="submit" className="c-login__button" state={this.state.loading ? 'loading' : ''}>Login</Button>
							</div>
						</div>
					</div>
					<div className="o-layout__item u-width-12/12 u-width-6/12@md3">
						<img src={this.state.randomBanner} className="c-login__banner" alt="Visions & Values" srcSet={`${this.state.randomBanner} 1x, ${this.state.randomBanner2x} 2x`} />
					</div>
				</div>
			</form>
		);
	}
}

export default Login;