import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Icon from './Icon';
import Expander from './Expander';
import Modal from './Modal';

const HeaderStatus = (props) => {
	if(!props.syncActive && props.updateAvailable && props.online) {
		return (
			<Modal title="Update Available" active={true} size="small" showCloseButton={false} showCloseIcon={false}>
				<div className="o-box o-box--spacing-small">
					<p>An update is available for the Account Manager app.</p>
					<p class="u-align-center">
						<Button handleClick={props.handleAppUpdate}>Update Now</Button>
					</p>
				</div>
			</Modal>
		)
	}
	else {
		return (
			<Expander active={!props.online || props.syncActive || props.updateAvailable} className="c-header-status-bar">
				<div className={`o-box o-box--spacing-tiny c-box c-box--animated-background ${!props.online && !props.syncActive ? 'is-error' : ''} ${props.syncActive ? 'is-positive' : ''} ${props.updateAvailable ? 'c-box--info' : ''} u-align-center`}>
					{props.syncActive ? (
						<React.Fragment>
							<Icon use="loading" color="white" className="u-margin-right-tiny" />
							Syncing offline changes. This should only take a moment.
						</React.Fragment>
					) : null}
					{!props.online && !props.syncActive ? (
						<React.Fragment>No internet connection detected. Limited functionality available.</React.Fragment>
					) : null}
				</div>
			</Expander>
		)
	}
}

HeaderStatus.propTypes = {
	online: PropTypes.bool,
	syncActive: PropTypes.bool,
	updateAvailable: PropTypes.bool,
	handleAppUpdate: PropTypes.func
}

export default HeaderStatus;