import React from 'react';
import PropTypes from 'prop-types';

const Tabs = (props) => {
	return (
		<div className={`c-tabs ${props.collapse ? 'c-tabs--collapse@max-' + props.collapse : ''} ${props.className}`}>
			<ul className="o-layout o-layout--flush o-layout--fit-height c-tabs__layout">
				{props.children}
			</ul>
		</div>
	)
}

Tabs.propTypes = {
	collapse: PropTypes.string,
	className: PropTypes.string
}

export default Tabs;