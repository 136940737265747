import React from 'react';
import PropTypes from 'prop-types';

import InfoRow from './InfoRow';
import InfoRowSwitch from './InfoRowSwitch';
import InfoRowUrl from './InfoRowUrl';
import InfoRowModal from './InfoRowModal';
import Tooltip from './Tooltip';
import StyledBox from './StyledBox';
import DealerContacts from './DealerContacts';
import DealerNotes from './DealerNotes';
import DealerCalculatorSettings from './DealerCalculatorSettings';

import configData from '../settings.js';

class DealerDetailsOverview extends React.Component {
	static propTypes = {
		details: PropTypes.array,
		credentials: PropTypes.object,
		online: PropTypes.bool,
		name: PropTypes.string,
		fieldData: PropTypes.object,
		updateFieldData: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {
			tempNotes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut consectetur magna eget neque gravida, quis rutrum erat dignissim. Sed fringilla dui sit amet libero faucibus facilisis. In eu eros commodo, iaculis neque a, consequat nisl. Phasellus et eros tincidunt, placerat arcu eu, lacinia tellus. Etiam cursus erat magna, quis tincidunt libero molestie a. Ut scelerisque nulla quis mattis tristique. Aenean enim ligula, elementum euismod lorem eu, vulputate malesuada mauris. Morbi porttitor mi non auctor dignissim.",
			tooltipMessages: {
				key_accman: 'AKA: Key Account Manager',
				send_snags: 'When on, payout error emails will be sent when something is wrong with payout (Such as needing a clearer copy of ID for example).',
				iq_dealerzone: 'When on, enables Intelligent Quote (IQ) functionality on the manage page in Dealerzone.',
				hpnf_primary: "AKA: Enables HP No Fees as the dealer's primary product.",
				check_opa: "Dealer operating agreement in place?"
			},
			fieldProps: {
				ice_id: {
					type: 'number'
				},
				comp_no: {
					type: 'number'
				},
				vat_no: {
					type: 'number'
				},
				fca_no: {
					type: 'number'
				},
				net_worth: {
					type: 'currency'
				},
				phone: {
					type: 'tel',
					pattern: '^(01|02|03|07)[0-9]{9}',
					patternMismatchMsg: 'Please enter a contact number with 11 numbers which begins with either 01, 02, 03 or 07'
				},
				fax: {
					type: 'tel',
					pattern: '^(01|02|03|07)[0-9]{9}',
					patternMismatchMsg: 'Please enter a contact number with 11 numbers which begins with either 01, 02, 03 or 07'
				},
				email: {
					type: 'email',
					typeMismatchMsg: 'Please enter a valid email address'
				},
				email2: {
					type: 'email',
					typeMismatchMsg: 'Please enter a valid email address'
				},
				email3: {
					type: 'email',
					typeMismatchMsg: 'Please enter a valid email address'
				},
				mobile: {
					type: 'tel',
					pattern: '^(01|02|03|07)[0-9]{9}',
					patternMismatchMsg: 'Please enter a contact number with 11 numbers which begins with either 01, 02, 03 or 07'
				},
				web_address: {
					type: 'url',
					typeMismatchMsg: "Your web address must begin with either 'http://' or 'https://'"
				},
				usr_pass: {
					type: 'password'
				},
				date_established: {
					placeholder: 'YYYY-MM-DD',
					pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}',
					type: 'date',
					patternMismatchMsg: 'Please enter a date in the YYYY-MM-DD format or select one using the calendar.'
				},
				calc_settings: {
					type: 'modal',
					modalSize: 'huge',
					modalTitle: 'Calculator Settings',
					contentComponent: DealerCalculatorSettings,
					contentComponentPropName: 'settings',
					contentComponentProps: {
						handleSave:(value) => this.props.updateFieldData('calc_settings', value, false),
						credentials: this.props.credentials,
						match: this.props.match
					}
				}
			}
		}
	}

	renderType = (fieldProp, fieldData) => {
		if(fieldProp) {
			return fieldProp.type;
		}
		else if(fieldData.list) {
			return 'select';
		}
		else {
			return '';
		}
	}

	renderInfoRow = (field) => {
		if(this.state.fieldProps[field.field] && this.state.fieldProps[field.field].type === 'modal') {
			const Elem = this.state.fieldProps[field.field].contentComponent;
			const elemProp = (propValue) => {
				return {
					[this.state.fieldProps[field.field].contentComponentPropName] : propValue
				}
			}
			return (
				<InfoRowModal
					name={field.display_name}
					size={this.state.fieldProps[field.field].modalSize}
					title={this.state.fieldProps[field.field].modalTitle}
				>
					<Elem {...this.state.fieldProps[field.field].contentComponentProps} {...elemProp(this.props.fieldData[field.field])} />
				</InfoRowModal>
			)
		}
		else if(this.props.fieldData[field.field] === "Yes" || this.props.fieldData[field.field] === "No" ) {
			return (
				<InfoRowSwitch 
					name={field.display_name}
					value={this.props.fieldData[field.field]}
					handleChange={(value) =>this.props.updateFieldData(field.field, value === 'Yes' ? 'No' : 'Yes')}
					active={this.props.fieldData[field.field] === "Yes"}
					tooltip={this.state.tooltipMessages[field.field] ? () => <Tooltip message={this.state.tooltipMessages[field.field]} small align="left"	/> : null}
					readOnly={field.readonly === 'readonly'}
				/>
			)
		}
		else if(field.field.includes('url')) {
			return (
				<InfoRowUrl
					name={field.display_name}
					url={`${configData.EVOLUTION_MAIN_ENDPOINT}/auth/token_redirect.php?token=${this.props.credentials.token}&user_id=${this.props.credentials.userId}&url=${this.props.fieldData[field.field].replace('https://www.evolutionfunding.com', '')}`}
					handleClick={() => this.props.setFrameView(this.props.fieldData[field.field])}
				/>
			)
		}
		else {
			return (
				<InfoRow
					name={field.display_name}
					fieldName={field.field}
					value={this.props.fieldData[field.field]}
					readOnly={field.readonly === 'readonly'}
					handleChange={(value) => this.props.updateFieldData(field.field, value)}
					tooltip={this.state.tooltipMessages[field.field] ? () => <Tooltip message={this.state.tooltipMessages[field.field]} small align="left"	/> : null}
					type={this.renderType(this.state.fieldProps[field.field], field)}
					typeMismatchMsg={this.state.fieldProps[field.field] ? this.state.fieldProps[field.field].typeMismatchMsg :  ''}
					patternMismatchMsg={this.state.fieldProps[field.field] ? this.state.fieldProps[field.field].patternMismatchMsg :  ''}
					pattern={this.state.fieldProps[field.field] ? this.state.fieldProps[field.field].pattern :  ''}
					selectOptions={field.list}
					placeholder={this.state.fieldProps[field.field] ? this.state.fieldProps[field.field].placeholder :  ''}
				/>
			)
		}
	}

	render() {
		return (
			<div className="o-layout o-layout--spacing-regular">
				{this.props.online ? (
					<div className="o-layout__item u-width-12/12 u-width-6/12@lg">
						{this.props.details.length ? (
								<React.Fragment>
									{this.props.details.map((segment, i) => 
										<StyledBox title={segment.display_name} overflow key={i}>
											{segment.fields.map((field, k) => 
												<React.Fragment key={i.toString() + k.toString()}>
													{Object.keys(this.props.fieldData).length ? this.renderInfoRow(field) : null}
												</React.Fragment>
											)}
										</StyledBox>
									)}
								</React.Fragment>
						) : null}
					</div>
				) : null}
				<div className="o-layout__item u-width-12/12 u-width-6/12@lg">
					<DealerContacts
						contacts={this.props.contacts}
						contactPositions={this.props.contactPositions}
						online={this.props.online}
						credentials={this.props.credentials}
						iceId={this.props.match.params.id}
						handleContactAdd={this.props.handleContactAdd}
						handleContactEdit={this.props.handleContactEdit}
						handleContactDelete={this.props.handleContactDelete}
					/>
					{this.props.online && this.props.notes ? (
						<DealerNotes notes={this.props.notes} />
					) : null}
				</div>
			</div>
		);
	}
}

export default DealerDetailsOverview;