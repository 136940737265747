import React from 'react';
import PropTypes from 'prop-types';

import MenuCard from './MenuCard'

export default class MenuCards extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		links: PropTypes.array,
		shortcuts: PropTypes.array,
		handleAddShortcut: PropTypes.func,
		handleRemoveShortcut: PropTypes.func,
		handleLogOut: PropTypes.func,
		online: PropTypes.bool,
		newContent: PropTypes.object
	};

	getNewContentCount = (id) => {
		if(this.props.newContent[id]) {
			let count = 0;
			for(let content in this.props.newContent[id]) {
				if(this.props.newContent[id][content] > this.props.newContent.today) {
					count++;
				}
			}
			if(count) {
				return count.toString();
			}
		}
	}

	render() {
		return (
			<div className="o-container o-container--fluid c-menu-cards">
				<div className="o-box o-box--spacing-small o-box--spacing-regular@md o-box--spacing-vertical c-menu-cards__tiles">
					{this.props.links.map((link, i) => 
						<div className="c-menu-cards__tile" key={i}>
							<MenuCard
								icon={link.id}
								title={link.title}
								description={link.description}
								to={link.to}
								disabled={link.disableIfOffline && !this.props.online}
								newContentNum={this.getNewContentCount(link.id)}
							 />
						 </div>
					)}
					<div className="c-menu-cards__tile">
						<MenuCard
							icon="logout"
							title="Log Out"
							description="Log out of the Account Manager Dashboard."
							handleClick={this.props.handleLogOut}
							disabled={!this.props.online}
						 />
					</div>
					{this.props.shortcuts.map((shortcut, i) => 
						<div className="c-menu-cards__tile" key={i}>
							<MenuCard
								icon="shortcut"
								title={shortcut.name}
								description={shortcut.description}
								to={shortcut.url}
								showMinusIcon
								handleMinusIconClick={() => this.props.handleRemoveShortcut(i)}
							 />
						 </div>
					)}
					{!window.navigator.userAgent.includes('Electron') ? (
						<div className="c-menu-cards__tile">
							<MenuCard
								icon="add-shortcut"
								title="Add Shortcut"
								description="Add your own custom shortcut to pages for ease of access."
								hollow
								handleClick={this.props.handleAddShortcut}
							 />
						 </div>
					) : null}
				</div>
			</div>
		);
	}
}
