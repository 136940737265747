import React from 'react';
import PropTypes from 'prop-types';

import Switch from './Switch';

class InfoRowSwitch extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		handleChange: PropTypes.func,
		value: PropTypes.string,
		tooltip: PropTypes.func,
		active: PropTypes.bool,
		readOnly: PropTypes.bool
	};

	render() {
		return (
			<div className="c-info-row">
				<div className="c-info-row__label">
					{this.props.tooltip ? (
						<React.Fragment>
							<div className="u-margin-right-tiny">{this.props.name}</div>
							{this.props.tooltip()}
						</React.Fragment>
					) : this.props.name}
				</div>
				<div className="c-info-row__wrapper">
					<Switch
						name={this.props.name.replace(/\s+/g, '-').toLowerCase()}
						handleClick={() => this.props.handleChange(this.props.value)}
						active={this.props.active}
						className='c-info-row__switch'
						disabled={this.props.readOnly}
					/>
				</div>
			</div>
		);
	}
}

export default InfoRowSwitch;