import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from './components/Header';
import MenuCards from './components/MenuCards';
import DealerList from './components/DealerList';
import DealerDetails from './components/DealerDetails';
import FrameView from './components/FrameView';
import VideoView from './components/VideoView';
import PresentationView from './components/PresentationView';
import AddShortcutForm from './components/AddShortcutForm';
import Login from './components/Login';
import Loading from './components/Loading';
import StatusMsg from './components/StatusMsg';
import HeaderStatus from './components/HeaderStatus';
import ErrorView from './components/ErrorView';

import { login, logout, whoami } from './api/account';
import { getDealerList, getDealerFranchiseTypes, getAllDealerContacts, getDealerContactPositions, getDealerContactTypes, getDealerCallTypes } from './api/dealer';
import { parseLocalStorage, storeLocalStorage, removeLocalStorage, searchLocalStorage } from './modules/handleStorage';
import { onlineStatus } from './api/misc';
import { syncController } from './modules/sync';
import menuLinks from './data/menuLinks';
import options from './data/letterOptions';

import configData from './settings.js';
import Redirect from "./components/Redirect";

class App extends React.Component {

	// COMPONENT FUNCTIONS
	//------------------------------------------------------------------------------------------------

	constructor(props) {
		super(props);
		this.state = {
			addShortcutModalOpen: false,
			shortcutUrl: '',
			loggedIn: false,
			logInError: false,
			credentials: null,
			dealers: false,
			shortcuts: [],
			links: menuLinks,
			contacts: null,
			contactPosition: null,
			contactTypes: null,
			callTypes: null,
			syncActive: false,
			syncPending: false,
			online: null,
			tempMappings: null,
			updateAvailable: false,
			newContent: {
				'video': {
					'power-bi': new Date('04/20/2020').getTime()
				},
				'today': new Date().getTime()
			}
		}

		// If shortcuts exist in localStorage, use them
		if(localStorage.getItem('shortcuts')) {
			this.state.shortcuts = JSON.parse(localStorage.getItem('shortcuts'));
		}

		// If we have localStorage data which will trigger a sync, flag this so we can react accordingly
		this.state.syncPending = searchLocalStorage('offline');
	}

	componentDidMount() {
		const hash = new URLSearchParams(window.location.hash.substring(1));
		window.history.replaceState(null, '', window.location.href.split('#')[0]);
		if (hash.has('token') && hash.has('userId')) {
			const credentials = {
				token: null,
				userId: null,
			};
			storeLocalStorage('credentials', credentials);
			this.setState({
				loggedIn: true,
				logInError: false,
				credentials: credentials
			});

			whoami(credentials)
				.then(() => {
					this.triggerSync(credentials, this.state.online);
				})
				.catch((error) => {
					console.error(error);
				})
		}

		// Set event listeners to track connection status
		// If we're offline and then connection is restored, we authenticate with the server to make sure our token is still kosher
		window.addEventListener('online', () => {
			this.setState({
				online: true,
				syncPending: searchLocalStorage('offline')
			}, () => {
				if(this.state.loggedIn) {
					this.authenticate();
				}
			})
		})
		window.addEventListener('offline', () => {
			this.setState({
				online: false
			})
		})

		// Set event listener to track service worker content
		window.addEventListener('newContentAvailable', (e) => {
			this.setState({
				updateAvailable: true,
				serviceWorkerRef: e.detail.registration
			})
		})

		// If credentials exists in localStorage, restore the users session if it's a valid one
		// If we're offline, we won't be able to check so we'll restore just if credentials exist in localstorage, as they won't have access to API anyway
		// On online, we'll reauthenticate and log them out if false
		if(localStorage.getItem('credentials')) {
			whoami(JSON.parse(localStorage.getItem('credentials')))
			.then(() => {
				this.restoreSession(true);
			})
			.catch(() => {
				this.restoreSession(false);
			})
		}

		onlineStatus()
		.then(() => {
			this.setState({
				online: true,
				syncPending: searchLocalStorage('offline')
			})
		})
		.catch(() => {
			this.setState({
				online: false
			})
		})
	}


	// SHORTCUT FUNCTIONS
	//------------------------------------------------------------------------------------------------

	openShortcutModal = (url) => {
		this.setState({
			addShortcutModalOpen: true,
			shortcutUrl: typeof url === 'string' ? url : ''
		})
	}

	closeShortcutModal = () => {
		if(window.confirm("Are you sure you want to cancel?")) {
			this.setState({
				addShortcutModalOpen: false,
				shortcutUrl: ''
			})
		}
	}

	addShortcut = (shortcutObj) => {
		const shortcuts = [...this.state.shortcuts];
		shortcuts.push(shortcutObj);
		this.setState({
			shortcuts: shortcuts,
			addShortcutModalOpen: false,
			shortcutUrl: ''
		}, () => {
			storeLocalStorage('shortcuts', [...this.state.shortcuts])
		})
	}

	removeShortcut = (index) => {
		if(prompt) {
			if(window.confirm("Are you sure you want to remove this shortcut?")) {
				const shortcuts = [...this.state.shortcuts];
				shortcuts.splice(index, 1);
				this.setState({
					shortcuts: shortcuts
				}, () => {
					storeLocalStorage('shortcuts', [...this.state.shortcuts])
				})
			}
		}
	}


	// ACCOUNT FUNCTIONS
	//------------------------------------------------------------------------------------------------

	restoreSession = (online) => {
		if(online) {
			whoami(JSON.parse(localStorage.getItem('credentials')))
			.then((response) => {
				if(response.name && response.id) {
					this.setState({
						credentials: JSON.parse(localStorage.getItem('credentials')),
						loggedIn: true
					}, () => {
						this.triggerSync(this.state.credentials, online);
					})
				}
				else {
					this.logOut();
				}
			})
			.catch((error) => {
				this.logOut();
			});
		}
		else {
			this.setState({
				credentials: JSON.parse(localStorage.getItem('credentials')),
				loggedIn: true
			}, () => {
				this.getAllData(this.state.credentials);
			})
		}
	}

	authenticate = () => {
		whoami(this.state.credentials)
		.then((response) => {
			if(response.id && response.name) {
				this.triggerSync(this.state.credentials, this.state.online);
			}
			else {
				console.log("🤬 Credentials don't authenticate, closing session");
				this.logOut();
			}
		})
	}

	handleLogIn = () => {
		login()
			.then((response) => {
				window.location = response.redirect_url
			})
			.catch((error) => {
				console.log(error);
			});
	}

	handleLogOut = () => {
		logout(this.state.credentials)
			.then((response) => {
				window.location = response.redirect_url
			})
			.catch((error) => {
				console.log(error);
			});
	}

	logOut = () => {
		removeLocalStorage(['activity', 'callTypes', 'contactPositions', 'contactTypes', 'contacts', 'credentials', 'contactsAll', 'dealers', 'franchiseTypes']);
		this.setState({
			loggedIn: false,
			credentials: null,
		})
	}

	// DATA  FUNCTIONS
	//------------------------------------------------------------------------------------------------

	getDealerList = (credentials) => {
		getDealerList(credentials)
		.then((dealersList) => {
			if(typeof dealersList === 'object') {
				let dealers = [...dealersList];
				// Sort dealers into alphabetically seperated object
				const dealerLetters = {};
				for(let option in options) {
					let dealerArray;
					if(options[option] === '0-9') {
						dealerArray = dealers.filter((dealer) => dealer.name.match(/^\d/));
					}
					else {
						dealerArray = dealers.filter((dealer) => dealer.name.startsWith(options[option]));
					}
					if(dealerArray.length) {
						dealerLetters[options[option]] = dealerArray.sort(function(a, b) {
							if(a.name < b.name) { return -1; }
							if(a.name > b.name) { return 1; }
							return 0;
						})
					}
				}
				this.setState({
					dealers,
					dealerLetters
				})
			}
		})
		.catch((error) => {
			console.log(error);
		})
	}

	getDealerFranchiseTypesRun = (credentials) => {
		getDealerFranchiseTypes(credentials).then(response => {
			this.setState({
				franchiseTypes: response
			})
		})
		.catch((error) => {
			console.log(error);
		})
	}

	getAllDealerContacts = (credentials) => {
		getAllDealerContacts(credentials).then(response => {
			this.setState({
				contacts: response
			})
		})
		.catch((error) => {
			console.log(error);
		})
	}

	getDealerContactPositions = (credentials) => {
		getDealerContactPositions(credentials).then(response => {
			this.setState({
				contactPositions: response
			})
		})
		.catch((error) => {
			console.log(error);
		})
	}

	getDealerContactTypes = (credentials) => {
		getDealerContactTypes(credentials).then(response => {
			this.setState({
				contactTypes: response
			})
		})
		.catch((error) => {
			console.log(error);
		})
	}

	getDealerCallTypes = (credentials) => {
		getDealerCallTypes(credentials).then(response => {
			this.setState({
				callTypes: response
			})
		})
		.catch((error) => {
			console.log(error);
		})
	}

	getAllData = (credentials) => {
		this.getDealerList(credentials);
		this.getDealerFranchiseTypesRun(credentials);
		this.getAllDealerContacts(credentials);
		this.getDealerContactPositions(credentials);
		this.getDealerContactTypes(credentials);
		this.getDealerCallTypes(credentials);
	}

	// DATA SETTING FUNCTIONS
	//------------------------------------------------------------------------------------------------

	// Adds passed dealer to app state, having already been sent to API or scheduled
	// to once connection is restored
	addDealer = (dealer, isOffline) => {
		// Add to alphabetised dealers array
		const letter = dealer.name.charAt(0).toUpperCase();
		const dealerLetters = {...this.state.dealerLetters}
		if(!this.state.dealerLetters[letter]) {
			dealerLetters[letter] = [dealer];
		}
		else {
			dealerLetters[letter].push(dealer);
			// Resort alphabetically
			dealerLetters[letter].sort(function(a, b){
				if(a.name < b.name) { return -1; }
				if(a.name > b.name) { return 1; }
				return 0;
			})
		}
		// Add to standard dealers array
		const dealers = [...this.state.dealers];
		const dealerData = {
			iceId: dealer.iceId ? dealer.iceId : null,
			name: dealer.name,
			risk: null
		}
		dealers.push(dealerData);
		// Update all state
		this.setState({
			dealers,
			dealerLetters,
		}, () => {
			// Update cached list
			storeLocalStorage('dealers', dealers);
		});
	}

	// Remove contact from master list, having already been removed from DB and from local state for dealers
	removeContact = (id) => {
		// Update cached list (Master list)
		const cachedAll = parseLocalStorage('contactsAll');
		for(let i = 0; i < cachedAll.length; i++) {
			// eslint-disable-next-line no-console
			if(cachedAll[i].contact_id == id) {
				cachedAll.splice(i, 1);
			}
		}
		storeLocalStorage('contactsAll', cachedAll);
		this.setState({
			contacts: cachedAll
		})
	}

	// OFFLINE FUNCTIONS
	//------------------------------------------------------------------------------------------------

	setConnection = (online) => {
		this.setState({
			online
		})
	}

	triggerSync = (credentials, online) => {
		syncController(credentials, online)
		.then((mappings) => {
			// If we're currently on a temp dealer, send to actual dealer using mappings
			// Show green sync bar for 3 seconds
			this.setState({
				syncActive: true,
				tempMappings: mappings
			}, () => {
				setTimeout(() => {
					this.setState({
						syncActive: false,
						syncPending: false
					});
					this.getAllData(credentials);
				}, 3000);
			})
		})
		.catch((error) => {
			if(error) {
				console.log(error)
			}
			this.getAllData(credentials);
		});
	}

	handleAppUpdate = () => {
		if(!this.state.serviceWorkerRef.waiting) {
			// Just to ensure registration.waiting is available before
			// calling postMessage()
			return false;
		 }
		 this.state.serviceWorkerRef.waiting.postMessage('skipWaiting');
		 setTimeout(() => {
		 	window.location.reload();
		 }, 500);
	}


	// VIEW RENDERING FUNCTIONS
	//------------------------------------------------------------------------------------------------

	loadedDealerList = () => {
		return this.state.dealers ?
			<DealerList
				dealers={this.state.dealerLetters}
				rawDealers={this.state.dealers}
				franchiseTypes={this.state.franchiseTypes}
				credentials={this.state.credentials}
				onDealerAdd={(dealer, isOffline) => this.addDealer(dealer, isOffline)}
				online={this.state.online}
			/>
			:
			<Loading fullScreen />;
	}

	loadedMenuCards = () => {
		return (
			<MenuCards
				links={this.state.links}
				shortcuts={this.state.shortcuts}
				handleAddShortcut={this.openShortcutModal}
				handleRemoveShortcut={this.removeShortcut}
				handleLogOut={this.handleLogOut}
				online={this.state.online}
				newContent={this.state.newContent}
			/>
		)	
	}

	loadedDealerDetails = (props) => {
		return (
			<DealerDetails
				{...props}
				credentials={this.state.credentials}
				online={this.state.online}
				syncActive={this.state.syncActive}
				contacts={this.state.contacts}
				contactPositions={this.state.contactPositions}
				contactTypes={this.state.contactTypes}
				callTypes={this.state.callTypes}
				dealers={this.state.dealers}
				syncPending={this.state.syncPending}
				tempMappings={this.state.tempMappings}
				removeContact={this.removeContact}
			/>
		)
	}

	viewRenderer = () => {
		// If the app doesn't know online status yet, just show loading icon
		if(this.state.online === null) {
			return <Loading fullScreen />
		}
		// If we don't have credentials and we don't have internet, show error message
		else if(!localStorage.getItem('credentials') && !this.state.online) {
			return (
				<StatusMsg>
					<h2 className="c-type-alpha u-margin-bottom-small">Please connect to the internet to login</h2>
					<p>There seems to have been a problem establishing an internet connection, which is required to login. Please try again once connection is restored.</p>
				</StatusMsg>
			)
		}
		// If we don't have credentials, but we're online, show login form
		else if(!localStorage.getItem('credentials')) {
			return <Login handleSubmit={this.handleLogIn} error={this.state.logInError} />
		}
		// If we're online and are logged in, show content
		else 	if(this.state.loggedIn) {
			return (
				<React.Fragment>
					<HeaderStatus
						online={this.state.online}
						syncActive={this.state.syncActive}
						updateAvailable={this.state.updateAvailable}
						handleAppUpdate={this.handleAppUpdate}
					/>
					<Header
						links={this.state.links}
						shortcuts={this.state.shortcuts}
						handleRemoveShortcut={this.removeShortcut}
						handleAddShortcut={this.openShortcutModal}
						handleLogOut={this.handleLogOut}
						online={this.state.online}
					/>
					<main>
						<Switch>
							<Route path="/" exact render={this.loadedMenuCards} />
							<Route path="/dealer-list" component={this.loadedDealerList} exact />
							<Route path="/dealer/:id" render={(routeProps) => this.loadedDealerDetails(routeProps)} />
                            <Route
                                path="/videos"
                                render={()=>
                                    <Redirect
                                        url="https://pulse.evolutionfunding.com/Interact/Pages/Section/ContentListing.aspx?subsection=3813"
                                        name="Pulse"
                                    />
                                }
                            />
                            <Route
                                path="/sales-resources"
                                render={()=>
                                    <Redirect
                                        url="https://pulse.evolutionfunding.com/Interact/Pages/Section/ContentListing.aspx?subsection=3805"
                                        name="Pulse"
                                    />
                                }
                            />
                            <Route
                                path="/pulse"
                                render={()=>
                                    <Redirect
                                        url="https://pulse.evolutionfunding.com/"
                                        name="Pulse"
                                    />
                                }
                            />
							<Route path="/console" render={() => <FrameView disabled={!this.state.online} title="Management Console" url={`${configData.EVOLUTION_MAIN_ENDPOINT}/activity/frame/?path=${configData.EVOLUTION_MAIN_ENDPOINT}/management-console&name=Management Console&token=${this.state.credentials.token}&user_id=${this.state.credentials.userId}`} />} exact/>
							<Route path="/reports" render={() => <FrameView disabled={!this.state.online} title="Reports" url={`${configData.EVOLUTION_MAIN_ENDPOINT}/activity/frame/?path=${configData.EVOLUTION_MAIN_ENDPOINT}/reports&name=Reports&token=${this.state.credentials.token}&user_id=${this.state.credentials.userId}`} />} exact/>
							<Route path="/email-marketing" render={() => <FrameView disabled={!this.state.online} title="Email Marketing" url={`${configData.EVOLUTION_MAIN_ENDPOINT}/activity/frame/?path=${configData.EVOLUTION_MAIN_ENDPOINT}/email-marketing&name=Email Marketing&token=${this.state.credentials.token}&user_id=${this.state.credentials.userId}`} />} exact/>
							<Route path="/credit-safe" render={() => <FrameView disabled={!this.state.online} title="Credit Safe" url={`${configData.EVOLUTION_MAIN_ENDPOINT}/activity/frame/?path=${configData.EVOLUTION_MAIN_ENDPOINT}/management-console/creditsafe.php&name=Credit Safe&token=${this.state.credentials.token}&user_id=${this.state.credentials.userId}`} />} exact/>
							<Route component={ErrorView} />
						</Switch>
					</main>
					{this.state.addShortcutModalOpen ? (
						<AddShortcutForm active={this.state.addShortcutModalOpen} handleClose={this.closeShortcutModal} handleSubmit={this.addShortcut} url={this.state.shortcutUrl} />
					) : null}
				</React.Fragment>
			)
		}

	}

	render() {
		return (
			<Router>
				{this.viewRenderer()}
			</Router>
		);
	}
}

export default App;
