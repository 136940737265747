import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import Badge from './Badge';
import Icon from './Icon';

const MenuCard = (props) => {

	const handleMinusIconKeyPress = (e) => {
		if(e.which === 13) {
			props.handleMinusIconClick();
		}
	}

	if(props.hollow) {
		return (
			<div className={`o-box o-box--spacing-regular c-menu-card c-menu-card--hollow ${props.disabled ? 'is-disabled' : ''}`}>
				<Icon className={`c-menu-card__icon c-menu-card__icon--${props.icon}`} use={props.icon} handleClick={props.handleClick} />
				<div className="c-menu-card__title" onClick={props.handleClick}><h2 className="c-type-delta">{props.title}</h2></div>
				{props.description ? <p className="c-menu-card__description">{props.description}</p> : ''}
			</div>
		)
	}
	else if(!props.to) {
		return (
			<div className={`o-box o-box--spacing-regular c-box c-box--white c-box--shadow c-box--rounded c-menu-card ${props.disabled ? 'is-disabled' : ''}`} onClick={props.handleClick}>
				{props.showMinusIcon ? (
					<Icon className='c-menu-card__minus' use='circle-minus' handleClick={props.handleMinusIconClick} handleKeyPress={(e) => handleMinusIconKeyPress(e)} tabIndex="0" />
				) : null}
				<Icon className={`c-menu-card__icon c-menu-card__icon--${props.icon}`} use={props.icon} />
				<h2 className="c-menu-card__title c-type-delta">{props.title}</h2>
				{props.description ? <p className="c-menu-card__description">{props.description}</p> : ''}
			</div>
		)
	}
	else {
		return (
			<div className={`o-box o-box--spacing-regular c-box c-box--white c-box--shadow c-box--rounded c-menu-card ${props.disabled ? 'is-disabled' : ''}`} onClick={props.handleClick}>

				{props.newContentNum ? (
					<Badge className="c-menu-card__badge" round>{props.newContentNum}</Badge>
				) : null }

				{props.showMinusIcon ? (
					<Icon className='c-menu-card__minus' use='circle-minus' handleClick={props.handleMinusIconClick} handleKeyPress={(e) => handleMinusIconKeyPress(e)} tabIndex="0" />
				) : null}
				<Link to={props.to} tabIndex={props.disabled ? -1 : 0}>
					<Icon className={`c-menu-card__icon c-menu-card__icon--${props.icon}`} use={props.icon} />
				</Link>
				<Link className="c-menu-card__title" to={props.to} tabIndex={props.disabled ? -1 : 0}><h2 className="c-type-delta">{props.title}</h2></Link>
				{props.description ? <p className="c-menu-card__description">{props.description}</p> : ''}
			</div>
		)
	}
}

MenuCard.propTypes = {
	icon: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	to: PropTypes.string,
	hollow: PropTypes.bool,
	handleClick: PropTypes.func,
	showMinusIcon: PropTypes.bool,
	handleMinusIconClick: PropTypes.func,
	disabled: PropTypes.bool,
	newContentNum: PropTypes.string
}

export default MenuCard;