import React from 'react';
import { Link } from "react-router-dom";

const Error = (props) => {
	return (
		<div className="o-container u-full-height-header">
			<div className="o-layout o-layout--middle u-full-height-header">
				<div className="o-layout__item u-align-center">
					<h1 className="c-type-alpha">Page not found</h1>
					<p className="c-type-delta">This page doesn't exist, <Link className="c-type-link u-nowrap" to="/">click here</Link> to return home.</p>
				</div>
			</div>			
		</div>
	)
}

export default Error;