import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Modal from './Modal';

class InfoRowModal extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		tooltip: PropTypes.func,
		size: PropTypes.string,
		title: PropTypes.string,
		handleChange: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false
		}
	}

	toggleModal = (value) => {
		this.setState({
			modalOpen: value
		})
	}

	render() {
		return (
			<div className="c-info-row">
				{this.state.modalOpen ? (
					<Modal
						handleClose={() => this.toggleModal(false)}
						size={this.props.size}
						title={this.props.title}
					>
						{React.cloneElement(this.props.children, { handleClose: this.toggleModal })}
					</Modal>
				) : null}
				<div className="c-info-row__label">
					{this.props.tooltip ? (
						<React.Fragment>
							<div className="u-margin-right-tiny">{this.props.name}</div>
							{this.props.tooltip()}
						</React.Fragment>
					) : this.props.name}
				</div>
				<div className="c-info-row__wrapper">
					<Button className="c-info-row__button" handleClick={() => this.toggleModal(true)}>Open</Button>
				</div>
			</div>
		);
	}
}

export default InfoRowModal;