import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';

class Expander extends React.Component {
	static propTypes = {
		title: PropTypes.string,
		className: PropTypes.string,
		active: PropTypes.bool,
		disabled: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {
			active: false
		}
		if(props.active) {
			this.state.active = true;
		}
	}

	toggleActive = () => {
		this.setState({
			active: !this.state.active
		})
	}

	handleKeyPress = (e) => {
		if(e.which === 12) {
			this.toggleActive();
		}
	}

	componentDidUpdate(prevProps) {
		// If active state is being managed externally via props, sync local active state boolean
		if(prevProps.active !== this.props.active) {
			this.setState({
				active: this.props.active
			});
		}
	}
 
	render() {
		return (
			<div className={`c-expander ${this.state.active ? 'is-active' : ''} ${this.props.disabled ? 'is-disabled' : ''} ${this.props.className}`}>
				{this.props.title ? (
					<div className="o-box o-box--spacing-small c-expander__title" tabIndex="0" onClick={this.toggleActive} onKeyPress={(e) => this.handleKeyPress(e)}>
						<div className="o-layout o-layout--middle">
							<div className="o-layout__item u-width-grow">
								<h3 className="c-type-echo">{this.props.title}</h3>
							</div>
							{!this.props.disabled ? (
								<div className="o-layout__item u-width-shrink">
									<Icon use="arrow" className="c-expander__arrow" />
								</div>
							) : null}
						</div>
					</div>
				) : null}
				{!this.props.disabled ? (
					<div className="c-expander__content">
						<div className="c-expander__inner-content">
							{this.props.children}
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

export default Expander;