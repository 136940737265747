import React, { Component } from "react";

export class Redirect extends Component {
    constructor( props ){
        super();
        this.state = { ...props };
    }
    componentWillMount(){
        window.open(this.state.url, '_blank');
    }
    render(){
        return (
            <div className="o-container">
                <div className="o-box o-box--spacing-small o-box--spacing-regular@md o-box--spacing-vertical">
                    <div className="o-layout">
                        <div className="o-layout__item u-width-12/12">
                            <h1>Redirected to <a target="_blank" href={this.state.url}>Pulse. </a><a href="/">Click here</a> to go back </h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Redirect;