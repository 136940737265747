import React from 'react';
import PropTypes from 'prop-types';

const SubHeader = (props) => {
	return (
		<div className={`o-box ${!props.removePadding ? 'o-box--spacing-small' : ''} o-box--spacing-vertical c-subheader ${props.fixContentMargins ? 'c-subheader--padding' : ''}`}>
			<div className="o-container o-container--fluid">
				{props.children}
			</div>
		</div>
	)
}

SubHeader.propTypes = {
	removePadding: PropTypes.bool,
	fixContentMargins: PropTypes.bool
}

export default SubHeader;