import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';

const Tooltip = (props) => {
	return (
		<div className={`c-tooltip ${props.className} ${props.invert ? 'c-tooltip--invert' : ''} ${props.small ? 'c-tooltip--small' : ''} ${props.align ? 'c-tooltip--' + props.align : ''}`}>
			<div className="c-tooltip__icon" tabIndex="0"><Icon use="info" /></div>
			<div className="c-tooltip__message">{props.message}</div>
		</div>
	)
}

Tooltip.propTypes = {
	message: PropTypes.oneOfType([
		PropTypes.object.isRequired,
		PropTypes.string.isRequired
	]),
	small: PropTypes.bool,
	align: PropTypes.string,
	className: PropTypes.string,
	invert: PropTypes.bool
}

export default Tooltip;