import post from '../modules/post';
import { syncSetup } from '../modules/sync';

import configData from '../settings.js';

export function addDealer(credentials, dealer) {
	return new Promise((resolve, reject) => {
		const postData = {
			name: dealer.name,
			legal_name: dealer.legalName,
			franchise_type: dealer.franchise,
			phone: dealer.contact,
			email: dealer.email,
			fax: dealer.fax,
			comp_no: dealer.companyNumber,
			vat_no: dealer.vat,
			postcode: dealer.postcode,
			password: dealer.password,
			cred_brokerage: dealer.creditBrokerage ? 1 : 0,
			debt_coun: dealer.debtCounselling ? 1 : 0,
			debt_adjust: dealer.debtAdjusting ? 1 : 0,
			temp_ice_id: dealer.iceId
		}
		post(configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=newdealer', postData)
		.then((response) => {
			resolve(response);
		})
		.catch((error) => {
			syncSetup('offlineDealers', postData, 'dealers');
			reject(error);
		})
	});
}

export function addActivityVisit(credentials, visit, iceId) {
	return new Promise((resolve, reject) => {
		const postData = {
			call_type1: visit.contactType1,
			call_type2: visit.contactType2,
			call_type3: visit.contactType3,
			ice_id: iceId,
			contact_id: visit.visited,
			contact_type: visit.contactMethod,
			latitude: visit.lat,
			longitude: visit.lng,
			comments: encodeURIComponent(visit.comments),
			queued_date: visit.dateTime
		}
		post(configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=addcall', postData)
		.then((response) => {
			resolve(response);
		})
		.catch((error) => {
			syncSetup('offlineDealerActivity', postData, 'dealer activity');
			reject(error);
		})
	});
}

export function deleteDealerContact(credentials, contactId) {
	return new Promise((resolve, reject) => {
		let postData = {}
		post(configData.EVOLUTION_MAIN_ENDPOINT + `/activity/f.php?f=contactdelete&id=${contactId}`, postData)
		.then((response) => {
			resolve(response);
		})
		.catch((error) => {
			syncSetup('offlineDeletedDealerContacts', postData, 'deleted dealer contact', `&id=${contactId}`);
			reject(error);
		})
	});
}

export function addDealerContact(credentials, name, position, email, telephone, iceId, evo_emails, temp_contact_id) {
	return new Promise((resolve, reject) => {
		const postData = {
			name,
			position,
			email,
			telephone,
			dealer_id: iceId,
			evo_emails,
			temp_contact_id
		}
		post(configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=contactadd', postData)
		.then((response) => {
			resolve(response);
		})
		.catch((error) => {
			syncSetup('offlineDealerContacts', postData, 'dealer contact');
			reject(error);
		})
	});
}

export function editDealerContact(credentials, contact) {
	return new Promise((resolve, reject) => {
		const postData = {
			id: contact.id || contact.temp_contact_id,
			name: contact.name,
			position: contact.position,
			email: contact.email,
			telephone: contact.telephone,
			evo_emails: contact.evo_emails
		};
		post(configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=contactedit', postData)
		.then((response) => {
			resolve(response);
		})
		.catch((error) => {
			syncSetup('offlineDealerContactEdits', postData, 'dealer contact edit');
			reject(error);
		})
	});
}

export function updateDealerData(credentials, iceId, field, value) {
	return post(configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=updatedetail', {
		ice_id: iceId,
		field: field,
		value: value
	});
}

export function updateDealerCalculator(credentials, iceId, rb, hp, nf) {
	const createFields = (keyName, obj) => {
		const newObj = {}
		for(let key in obj) {
			newObj[`${keyName}[${key}]`] = obj[key]
		}
		return newObj;
	}
	return post(configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=updatecalc', {
		ice_id: iceId,
		...createFields('rb', rb),
		...createFields('hp', hp),
		...createFields('nf', nf)
	});
}
