import React from 'react';
import PropTypes from 'prop-types';

import StatusMsg from './StatusMsg';
import SubHeader from './SubHeader';
import Button from './Button';
import MediaCollection from './MediaCollection';
import VideoRow from './VideoRow';

class VideoView extends React.Component {
	static propTypes = {
		title: PropTypes.string,
		disabled: PropTypes.bool,
		newContent: PropTypes.object
	};

	static defaultProps = {
		disabled: false
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	
	render() {
		return (
			<React.Fragment>
				{!this.props.disabled ? (
					<React.Fragment>
						<SubHeader>
							<div className="o-layout o-layout--middle">
								<div className="o-layout__item u-width-grow u-width-auto@lg u-margin-bottom-small@sm2 u-margin-bottom-none@lg">
									<h2 className="c-type-charlie c-type--invert">Video Resources</h2>
								</div>
								<div className="o-layout__item u-align-right u-width-auto@lg u-hide@max-lg">
									<Button iconSide="left" icon="circle-back" to='/'>Go Back</Button>
								</div>
							</div>
						</SubHeader>
						<div className="o-container">
							<div className="o-box o-box--spacing-small o-box--spacing-regular@md o-box--spacing-large@md2 o-box--spacing-vertical u-width-10/12@sm3 u-offset-1/12@sm3 u-width-12/12@md u-width-10/12@lg u-offset-1/12@lg">

								<MediaCollection>

									<VideoRow title="Digital Customer Journey" description="Evolution Funding has created a digital customer journey that transforms the customer experience of taking out motor finance. Watch to find out how we achieve this for both our dealer partners and their customers." copyLink="https://www.youtube.com/watch?v=rrFIXmFRNq8" videoId="rrFIXmFRNq8" />

									<VideoRow title="Evolution Funding's one-of-a- kind decisioning engine" description="Evolution Funding's one-of-a-kind decisioning engine allows Evolution to consistently and robustly deliver the best outcome for the customer." copyLink="https://www.youtube.com/watch?v=4TrAT-lIm40" videoId="4TrAT-lIm40" />

									<VideoRow title="Inside Evolution Extra, a division of Evolution Funding" description="Evolution Extra is Evolution Funding's specialist, rate for risk, deal-making department. Find out more about how Evolution Extra is helping customers to buy the car of their dreams." copyLink="https://www.youtube.com/watch?v=1FEZ11koWtU&t=3s" videoId="1FEZ11koWtU" />

								</MediaCollection>

								<MediaCollection title="Adequate Explainer Videos" description="Forming part of our e-Sign journey, the Adequate Explainer ensures that the customer is fully informed about the credit agreement they are entering into.">

									<VideoRow title="Hire Purchase (HP) or Conditional Sale Explanation" description="This adequate explanation of Hire Purchase (HP) or Conditional Sale is provided by Evolution Funding, the UK's leading motor finance broker." copyLink="https://www.youtube.com/watch?v=pgf85hhgvvY" videoId="pgf85hhgvvY" />

									<VideoRow title="Personal Contract Purchase (PCP) Explanation" description="This adequate explanation of Personal Contract Purchase (PCP) is provided by Evolution Funding, the UK's leading motor finance broker." copyLink="https://www.youtube.com/watch?v=VlStrKkZDOk" videoId="VlStrKkZDOk" />

									<VideoRow title="Fixed Sum Loan Agreement Explanation" description="This adequate explanation of the Fixed Sum Loan Agreement is provided by Evolution Funding, the UK's leading motor finance broker." copyLink="https://www.youtube.com/watch?v=_9Aqk43hgAQ" videoId="_9Aqk43hgAQ" />

								</MediaCollection>

								<MediaCollection title="Product Explainer Videos" description="Designed to better inform the motor finance customer and help them to decide on the best solution for their circumstances.">

									<VideoRow title="Hire Purchase (HP) Explained" description="Thinking of financing your next car and wondering what your options are? The UK's leading motor finance broker, Evolution Funding, explains how Hire Purchase or 'HP' works." copyLink="https://www.youtube.com/watch?v=GnyWPWJAtQs" videoId="GnyWPWJAtQs" />

									<VideoRow title="Personal Contract Purchase (PCP) Explanation" description="This adequate explanation of Personal Contract Purchase (PCP) is provided by Evolution Funding, the UK's leading motor finance broker." copyLink="https://www.youtube.com/watch?v=ybqauzObs-c" videoId="ybqauzObs-c" />

								</MediaCollection>

								<MediaCollection title="Demo Videos" description="Bringing you up-to-speed with our latest technology and processes.">

									<VideoRow title="Dealer Oversight Demo Video" description="This video will take you through the Dealer Oversight application, which allows you to electronically submit a full dealer setup pack to our system for review." localVideoId="Dealer-Oversight-Demo-V2" />

									<VideoRow title="MyGarage:DZ Demo Video" description="This video will take you through MyGarage:DZ, which facilitates safe, secure and GDPR-compliant proof collection and submission." localVideoId="MyGarageDZ-Demo-Video-V2" />

									<VideoRow title="e-Sign Anywhere Demo Video" description="This video will take you through e-Sign Anywhere, our secure, online portal which allows the customer to complete a deal from the comfort of their own home or in the showroom." localVideoId="e-Sign-Anywhere-Demo-Video-V3" />

									<VideoRow title="DocSign Anywhere Demo Video" description="This video will take you through DocSign Anywhere, our secure, online portal which can be used where a funder does not have e-Sign facilities." localVideoId="DocSign-Demo-Video-V3" />

									<VideoRow title="Self-billing Walkthrough Video" description="This video will take you through the self-billing solution and Dealer Invoice Generator - our new solution which allows Evolution to electronically generate an invoice on the dealer's behalf." localVideoId="Self-billing-Walkthrough" />

								</MediaCollection>

								<MediaCollection title="Webinars" description="Recordings of all Evolution updates delivered via live webinar.">

									<VideoRow
										title="Commissions & Gateways Scheme 2020"
										description="This video shows the launch of the new Account Manager bonus scheme, designed to align with the RSD bonus scheme and company objectives."
										localVideoId="Commissions-and-Gateways-Scheme-2020"
										new={this.props.newContent['commissions'] > this.props.newContent['today']}
										/>

									<VideoRow
										title="Power BI Reporting for AMs Demo"
										description="This video, presented by Meg Hancock-Orloff, talks you through the new reporting suite which allows AMs to record, track and report on dealer management activity. "
										localVideoId="Power-BI-Reporting-for-AMs-Demo"
										new={this.props.newContent['power-bi'] > this.props.newContent['today']}
										/>

								</MediaCollection>
							</div>
						</div>
					</React.Fragment>
				) : (
					<StatusMsg>
						<h2 className="c-type-alpha u-margin-bottom-small">This page isn't available offline</h2>
						<p>Unfortunately, this page isn't available without internet. Please try again once your connection is restored.</p>
					</StatusMsg>
				)}
			</React.Fragment>
		);
	}
}

export default VideoView;
