import React from 'react';
import PropTypes from 'prop-types';

import StatusMsg from './StatusMsg';

class FrameView extends React.Component {
	static propTypes = {
		title: PropTypes.string,
		url: PropTypes.string.isRequired,
		showBorder: PropTypes.bool,
		disabled: PropTypes.bool
	};

	static defaultProps = {
		showBorder: true,
		disabled: false
	}

	goBack = () => {
		try {
			window.iframe.contentWindow.history.back();
		}
		catch(error) {
			console.log(error);
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		const root = document.getElementsByTagName('html')[0];
		root.classList.add('u-hide-scrollbars');
	}

	componentWillUnmount() {
		const root = document.getElementsByTagName('html')[0];
		root.classList.remove('u-hide-scrollbars');
	}

	render() {
		return (
			<React.Fragment>
				{!this.props.disabled ? (
					<iframe src={this.props.url} className="c-frame-view" id="iframe" title="frameview" />
				) : (
					<StatusMsg>
						<h2 className="c-type-alpha u-margin-bottom-small">This page isn't available offline</h2>
						<p>Unfortunately, this page isn't available without internet. Please try again once your connection is restored.</p>
					</StatusMsg>
				)}
			</React.Fragment>
		);
	}
}

export default FrameView;
