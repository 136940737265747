import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Icon from './Icon';
import Loading from './Loading';

class Form extends React.Component {

	static propTypes = {
		handleSubmit: PropTypes.func,
		handleCancel: PropTypes.func,
		handleReset: PropTypes.func,
		loading: PropTypes.bool,
		success: PropTypes.bool,
		failure: PropTypes.bool,
		errorMsg: PropTypes.string,
		item: PropTypes.string,
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.handleSubmit();
	}

	render() {
		if(this.props.success) {
			return (
				<div className="o-box o-box--spacing-large u-align-center c-form">
					<Icon use="circle-tick" className="c-form__icon is-success u-margin-bottom-small" />
					<h2 className="c-type-delta is-success u-margin-bottom-regular">
						Success! Your new {this.props.item} has been added
					</h2>
					<Button handleClick={() => this.props.handleCancel()} icon="close">Close</Button>
				</div>
			)
		}
		else if(this.props.failure) {
			return (
				<div className="o-box o-box--spacing-large u-align-center c-form">
					<Icon use="circle-close" className="c-form__icon is-error u-margin-bottom-small" />
					<h2 className="c-type-delta is-error u-margin-bottom-regular">{this.props.errorMsg ? this.props.errorMsg : `Your new ${this.props.item} couldn't be added at this time. Please try again later.`}</h2>
					<Button handleClick={() => this.props.handleReset()}>Go back</Button>
				</div>
			)
		}
		else if(this.props.loading) {
			return (
				<div className="o-box o-box--spacing-large c-dealer-list-form u-align-center c-form">
					<h2 className="c-type-delta u-margin-bottom-regular">Your {this.props.item} is being added</h2>
					<Loading timeout={false} />
				</div>
			)
		}
		else {
			return (
				<form className="c-form" onSubmit={(e) => this.handleSubmit(e)}>
					<div className="o-box o-box--spacing-small o-box--spacing-large@md">
						{this.props.children}
					</div>
					<div className="o-box o-box--spacing-small o-box--spacing-regular@md c-box u-align-center">
						<Button type="submit" className="u-margin-right-small">Save</Button>
						<Button secondary handleClick={() => this.props.handleCancel()} dummy>Cancel</Button>
					</div>
				</form>
			);
		}
	}
}

export default Form;
