import React, { Component } from 'react';

import Button from './Button';
import Icon from './Icon';
import Menu from './Menu';
import HamburgerButton from './HamburgerButton';

class Header extends Component {

	constructor(props) {
		super(props);
		this.state = {
			menuActive: false
		}
	}

	toggleMenu = () => {
		this.setState({
			menuActive: !this.state.menuActive
		});
	}

	onClose = () => {
		this.setState({
			menuActive: false
		})
	}

	render() {
		return (
			<React.Fragment>
				<header className="o-container o-container--fluid c-box c-box--white c-box--bordered c-header">
					<div className="o-layout o-layout--middle o-layout--fit">
						<div className="o-layout__item">
							<Icon className="c-header__logo" use="logo" />
						</div>
						<div className="c-header__title u-hide@max-lg">
							<h1 className="c-type-echo">Account Manager Dashboard</h1>
						</div>
						<div className="o-layout__item u-width-shrink">
							<Button
								className="u-margin-right-small u-hide@max-sm3"
								icon="home"
								secondary
								tabIndex="0"
								to="/"
							>Home</Button>
							<HamburgerButton
								active={this.state.menuActive}
								handleClick={this.toggleMenu}
							/>
						</div>
					</div>
				</header>
				<Menu
					active={this.state.menuActive}
					onCloseMenu={this.onClose}
					links={this.props.links}
					shortcuts={this.props.shortcuts}
					handleRemoveShortcut={this.props.handleRemoveShortcut}
					handleAddShortcut={this.props.handleAddShortcut}
					handleLogOut={this.props.handleLogOut}
					online={this.props.online}
				/>
			</React.Fragment>
		);
	}
}

export default Header;
