import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Input from './Input';
import Modal from './Modal';

class AddShortcutForm extends React.Component {
	static propTypes = {
		handleClose: PropTypes.func,
		handleSubmit: PropTypes.func,
		url: PropTypes.string
	};

	constructor(props) {
		super(props);
		this.state = {
			name: '',
			url: '',
			description: ''
		}
		if(props.url) {
			this.state.url = props.url;
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();
		// Remove full path from URL
		this.setState({
			url: this.state.url.replace(`${window.location.origin}`, '')
		}, () => {
			this.props.handleSubmit({...this.state});
		})
	}

	updateState = (state, value) => {
		this.setState({
			[state] : value
		})
	}

	render() {
		return (
			<Modal title="Add Shortcut" handleClose={this.props.handleClose}>
				<form onSubmit={(e) => this.handleSubmit(e)}>
					<div className="o-box o-box--spacing-small o-box--spacing-large@md">
						<div className="o-layout">
							<div className="o-layout__item u-width-12/12 u-width-6/12@md u-margin-bottom-small u-margin-bottom-regular@md">
								<Input name="name" label="Shortcut Name*" required valueMissingMsg="Please name your shortcut" value={this.state.name} handleChange={(value) => this.updateState('name', value)} />
							</div>
							<div className="o-layout__item u-width-12/12 u-width-6/12@md u-margin-bottom-small u-margin-bottom-regular@md">
								<Input name="url" label="Web Address*" type="url" required valueMissingMsg="Please add your shortcut's web address" typeMismatchMsg="Your web address must begin with either 'http://' or 'https://'" value={this.state.url} handleChange={(value) => this.updateState('url', value)} />
							</div>
							<div className="o-layout__item u-width-12/12">
								<Input name="description" type="textarea" label="Description" value={this.state.description} handleChange={(value) => this.updateState('description', value)} maxLength="75" />
							</div>
						</div>
					</div>
					<div className="o-box o-box--spacing-small o-box--spacing-regular@md c-box u-align-center">
						<Button type="submit" className="u-margin-right-small">Add</Button>
						<Button secondary handleClick={this.props.handleClose} dummy>Cancel</Button>
					</div>
				</form>
			</Modal>
		);
	}
}

export default AddShortcutForm;