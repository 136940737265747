import React from 'react';
import PropTypes from 'prop-types';

const Icon = (props) => {
	if(props.handleClick || props.handleKeyPress) {
		return (
			<div className={`${props.className} c-icon ${props.color ? `c-icon--${props.color}` :  ''} c-icon--${props.use} u-pointer`} tabIndex={props.tabIndex} onClick={props.handleClick} onKeyPress={props.handleKeyPress}>
				<svg>
					<use xlinkHref={`#${props.use}`} />
				</svg>
			</div>
		)
	}
	return (
		<svg className={`${props.className} c-icon ${props.color ? `c-icon--${props.color}` :  ''} c-icon--${props.use}`}>
			<use xlinkHref={`#${props.use}`} />
		</svg>
	);
}

Icon.propTypes = {
	use: PropTypes.string.isRequired,
	className: PropTypes.string,
	color: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool
	]),
	tabIndex: PropTypes.string,
	handleClick: PropTypes.func,
	handleKeyPress: PropTypes.func
};

Icon.defaultProps = {
	className: '',
	color: false
};

export default Icon;
