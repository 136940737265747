import React from 'react';
import PropTypes from 'prop-types';

import Alphabet from './Alphabet';
import Button from './Button';
import StyledBox from './StyledBox';
import DealerListItem from './DealerListItem';
import Input from './Input';
import SubHeader from './SubHeader';
import AddDealerForm from './AddDealerForm';

import options from './../data/letterOptions';

export default class DealerList extends React.Component {

	static propTypes = {
		dealers: PropTypes.object,
		rawDealers: PropTypes.array,
		onDealerAdd: PropTypes.func,
		online: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {
			activeLetter: null,
			disabledLetters: [],
			searchTerm: '',
			addDealerModalOpen: false
		}
	}

	setActiveLetter = (letter) => {
		if(this.state.activeLetter === letter) {
			this.setState({
				activeLetter: null
			})
		}
		else {
			this.setState({
				activeLetter: letter,
				searchTerm: ''
			})
		}
	}

	setSearchTerm = (term) => {
		this.setState({
			searchTerm: term,
			activeLetter: null
		})
	}

	openModal = () => {
		this.setState({
			addDealerModalOpen: true
		});
	}

	closeModal = () => {
		this.setState({
			addDealerModalOpen: false,
		});
	}

	calculateDisabledLetter = (dealers) => {
		const disabledLetters = [];
		options.map((letter) => {
			if(!this.props.dealers.hasOwnProperty(letter)) {
				disabledLetters.push(letter);
			}
		})
		this.setState({
			disabledLetters
		})
	}

	componentDidUpdate(prevProps) {
		if(JSON.stringify(prevProps.dealers) !== JSON.stringify(this.props.dealers)) {
			this.calculateDisabledLetter(this.props.dealers);
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		if(this.props.dealers) {
			this.calculateDisabledLetter(this.props.dealers);
		}
	}

	alphabet = () => {
		if(this.props.dealers && Object.entries(this.props.dealers).length !== 0) {
			return (
				<div className="o-layout__item u-width-10/12@lg u-offset-1/12@lg u-margin-bottom-regular">
					<Alphabet
						activeLetter={this.state.activeLetter}
						disabledLetters={this.state.disabledLetters}
						handleClick={this.setActiveLetter}
					/>
				</div>
			)
		}
		return null;
	}

	searchInput = () => {
		if(this.props.dealers && Object.entries(this.props.dealers).length !== 0) {
			return (
				<div className="o-layout__item u-width-12/12 u-width-grow@lg">
					<Input
						invert
						leftLabel
						label="Search"
						placeholder="Eg York Audi (Name) or 8160 (Ice Id)"
						handleChange={this.setSearchTerm}
						value={this.state.searchTerm}
						name="search"
						tooltip="You can either search according to the dealers name or ice id"
					/>
				</div>
			)
		}
		return null;
	}

	dealerList = () => {
		if(this.props.dealers && Object.entries(this.props.dealers).length !== 0) {
			const dealerElems = [];
			if(this.state.activeLetter) {
				dealerElems.push(
					<StyledBox title={this.state.activeLetter} key='container'>
						{this.props.dealers[this.state.activeLetter].map((dealer, i) => 
							<DealerListItem
								name={dealer.name}
								path={`/dealer/${dealer.iceId}?name=${dealer.name}`}
								risk={dealer.risk}
								key={i}
							/>
						)}					
					</StyledBox>
				)
			}
			else if(this.state.searchTerm) {
				const matchingDealers = [];
				this.props.rawDealers.map((dealer) => {
					if(dealer.name.toLowerCase().includes(this.state.searchTerm.toLowerCase()) || dealer.iceId.toString().includes(this.state.searchTerm)) {
						matchingDealers.push(dealer);
					}
				})
				if(matchingDealers.length) {
					dealerElems.push(
						<StyledBox title={`${matchingDealers.length} ${matchingDealers.length === 1 ? 'dealer' : 'dealers'} matching '${this.state.searchTerm}'`} key={Math.random()}>
							{matchingDealers.map((dealer, i) => 
								<DealerListItem
									name={dealer.name}
									path={`/dealer/${dealer.iceId}?name=${dealer.name}`}
									risk={dealer.risk}
									key={i}
								/>
							)}
						</StyledBox>
					)
				}
				else {
					dealerElems.push(
						<React.Fragment>
							<h2 className="c-type-delta u-margin-bottom-small">{matchingDealers.length} dealers matching '{this.state.searchTerm}'</h2>
							<p>There were no dealers matching your search criteria.</p>
						</React.Fragment>
					)
				}
			}
			else {
				Object.keys(this.props.dealers).map((letter, i) => {
					dealerElems.push(
						<StyledBox title={letter} key={i}>
							{this.props.dealers[letter].map((dealer, k) => 
								<DealerListItem
									name={dealer.name}
									path={`/dealer/${dealer.iceId}?name=${dealer.name}`}
									risk={dealer.risk}
									key={k}
								/>
							)}
						</StyledBox>
					)
				})
			}
			return dealerElems;
		}
		else {
			return (
				<h2 className="c-type-delta u-align-center">No dealers are currently assigned to your account.</h2>
			);
		}
	}

	render() {
		return (
			<React.Fragment>
				<AddDealerForm
					active={this.state.addDealerModalOpen}
					handleClose={() => this.closeModal(true)}
					franchiseTypes={this.props.franchiseTypes}
					onDealerAdd={this.props.onDealerAdd}
					credentials={this.props.credentials}
					online={this.props.online}
				/>
				<SubHeader>
					<div className="o-layout o-layout--middle">
						<div className="o-layout__item u-width-grow u-width-auto@lg u-margin-bottom-small@sm2 u-margin-bottom-none@lg">
							<h2 className="c-type-charlie c-type--invert">Dealer List</h2>
						</div>
						<div className="o-layout__item u-align-right u-width-grow u-hide@lg u-margin-bottom-small@sm2 u-margin-bottom-none@lg">
							<Button icon="plus" className="u-hide@max-sm" handleClick={this.openModal}>Add Dealer</Button>
							<Button icon="plus" className="u-hide@sm" handleClick={this.openModal}>Add</Button>
						</div>
						{this.searchInput()}
						<div className="o-layout__item u-align-right u-width-auto@lg u-hide@max-lg">
							<Button icon="plus" handleClick={this.openModal}>Add Dealer</Button>
						</div>
					</div>
				</SubHeader>
				<div className="o-container">
					<div className="o-box o-box--spacing-small o-box--spacing-regular@md o-box--spacing-vertical">
						<div className="o-layout">
							{this.alphabet()}
							<div className="o-layout__item u-width-12/12">
								{this.dealerList()}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
