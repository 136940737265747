import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import Swipeable from 'react-swipeable';

import Icon from './Icon';

class Menu extends Component {
	static propTypes = {
		active: PropTypes.bool,
		onCloseMenu: PropTypes.func,
		links: PropTypes.array,
		shortcuts: PropTypes.array,
		handleRemoveShortcut: PropTypes.func,
		handleAddShortcut: PropTypes.func,
		handleLogOut: PropTypes.func,
		online: PropTypes.bool
	};

	static defaultProps = {
		online: true
	}

	constructor(props) {
		super(props);
		this.menuNode = React.createRef();
		this.state = {
			hide: true,
			active: false,
			showShortcut: true
		}
	}

	hideMenu = (e) => {
		if(this.state.active) {
			this.setState({
				hide: false
			})
			document.body.classList.add('is-fixed');
		}
		else {
			this.setState({
				hide: true
			})
			document.body.classList.remove('is-fixed');
		}
	}

	openMenu = () => {
		this.setState({
			active: true,
			hide: false
		})
	}

	closeMenu = () => {
		if(this.props.onCloseMenu) {
			this.props.onCloseMenu();
		}
		this.setState({
			active: false
		})
	}

	componentDidUpdate(prevProps) {
		if(this.props.active !== prevProps.active) {
			// Hide add current page to shortcut if we're on a temp dealer
			this.setState({
				showShortcut: !window.location.pathname.includes('temp-')
			}, () => {
				this.props.active ? this.openMenu() : this.closeMenu();
			})
		}
	}

	removeShortcutKeyPress = (e, shortcut) => {
		if(e.which === 13) {
			this.props.handleRemoveShortcut(shortcut)
		}
	}

	handleAddShortcut = (e, url) => {
		e.preventDefault();
		this.props.handleAddShortcut(url);
	}

	componentWillUnmount(){
		document.body.classList.remove('is-fixed');
	}

	render() {
		return (
			<Swipeable
				className={`o-cover c-menu ${this.state.active ? 'is-active' : ''} ${this.state.hide ? 'is-hidden' : ''} ${!this.props.online ? 'c-menu--large-top' : ''}`}
				ref={this.menuNode}
				onTransitionEnd={this.hideMenu}
				onSwipedRight={this.closeMenu}
			>
				<nav className="o-cover o-cover--right c-menu__box">
					<div className="c-menu__top"></div>
					<div className="o-box o-box--spacing-regular">
						<ul className="o-list-bare">
							<li className="u-margin-bottom-small">
								<NavLink exact onClick={this.closeMenu} className="c-type-foxtrot c-menu__item" activeClassName="is-active" to="/">Home</NavLink>
							</li>
							{this.props.links.map((link, i) => 
								<li className="u-margin-bottom-small" key={i}>
									<NavLink exact onClick={this.closeMenu} className={`c-type-foxtrot c-menu__item ${!this.props.online && link.disableIfOffline ? 'is-disabled' : ''}`} activeClassName="is-active" to={link.to} tabIndex={!this.props.online && link.disableIfOffline ? '-1' : '0'}>{link.title}</NavLink>
								</li>
							)}
							<li onClick={this.props.handleLogOut} className={`c-type-foxtrot c-menu__item ${!this.props.online ? 'is-disabled' : ''}`} tabIndex={!this.props.online ? '-1' : '0'}>Log Out</li>
						</ul>
					</div>
					{this.state.showShortcut ? (
						<React.Fragment>
							<div className="c-menu__spacer"></div>
							<div className="o-box o-box--spacing-regular">
								<ul className="o-list-bare">
									{this.props.shortcuts.map((shortcut, i) => 
										<li className="u-margin-bottom-small" key={i}>
											<NavLink exact onClick={this.closeMenu} className="c-type-foxtrot c-menu__item" activeClassName="is-active" to={shortcut.url}>{shortcut.name}</NavLink>
											<Icon className="c-menu__shortcut-control c-menu__shortcut-control--minus" use="circle-minus" tabIndex="0" handleClick={() => this.props.handleRemoveShortcut(i)} handleKeyPress={(e) => this.removeShortcutKeyPress(e, i)} />
										</li>
									)}
									<li>
										<a href="/" className="c-type-foxtrot c-menu__item" onClick={(e) => this.handleAddShortcut(e, window.location.href)}>
											<Icon className="c-menu__shortcut-control c-menu__shortcut-control--plus" use="circle-plus" />
											Add Current Page Shortcut
										</a>
									</li>
								</ul>
							</div>
						</React.Fragment>
					) : null}
				</nav>
			</Swipeable>
		);
	}
}

export default Menu;