import React from 'react';

const StatusMsg = (props) => {
	return (
		<div className="o-layout u-full-height o-layout--middle">
			<div className="o-layout__item u-align-center">
				{props.children}
			</div>
		</div>
	)
}

export default StatusMsg;