import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Input from './Input';
import StyledBox from './StyledBox';
import Modal from './Modal'
import Form from './Form'

import { zeroify } from './../modules/helpers';

class DealerDetailsActivity extends React.Component {
	static propTypes = {
		visits: PropTypes.array,
		handleNewVisit: PropTypes.func,
		contactTypes: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.array
		]),
		callTypes: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.array
		]),
		contacts: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.array
		]),
		modalOpen: PropTypes.bool,
		onModalClose: PropTypes.func,
		onModalReset: PropTypes.func,
		activitySuccess: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.state = this.defaultState();
	}

	defaultState = () => {
		const dateValue = (date, addTime = false, altFormat = false) => {
			if(addTime) {
				return `${date.getFullYear()}-${zeroify(date.getMonth() + 1)}-${zeroify(date.getDate())} ${zeroify(date.getHours())}:${zeroify(date.getMinutes())}:${zeroify(date.getSeconds())}`;
			}
			if(altFormat) {
				return `${zeroify(date.getDate())}/${zeroify(date.getMonth() + 1)}/${date.getFullYear()}`;
			}
			return `${date.getFullYear()}-${zeroify(date.getMonth() + 1)}-${zeroify(date.getDate())}`;
		}
		return {
			lat: null,
			lng: null,
			dateTime: dateValue(new Date(), true),
			date: dateValue(new Date()),
			altDate: dateValue(new Date(), false, true),
			visited: '',
			contactMethod: '',
			contactType1: '',
			contactType2: '',
			contactType3: '',
			comments: ''
		}
	}

	updateState = (state, value) => {
		this.setState({
			[state] : value
		})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.handleNewVisit({...this.state})
		this.setState(this.defaultState());
	}

	renderSelectOptions = (data) => {
		const options = [];
		if(data && data.length) {
			data.map((item, i) => {
				options.push(<option key={i} value={item}>{item}</option>);
				return options;
			})
		}
		else if(data) {
			Object.keys(data).map((key, i) => {
				options.push(<option key={i} value={data[key]}>{key}</option>);
				return options;
			})
		}
		return options;
	}

	contactsList = () => {
		const options = {};
		if(this.props.contacts) {
			Object.keys(this.props.contacts).map((key, i) => {
				options[this.props.contacts[key]['name']] = this.props.contacts[key]['id'] || this.props.contacts[key]['temp_contact_id'];
			})
		}
		return options;
	}

	componentWillMount() {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(position => {
				this.setState({
					lat: position.coords.latitude,
					lng: position.coords.longitude
				})
			});
		}
	}

	// updateDate = (value, dateObj) => {
	// 	const hours = dateObj.getHours().toString().length === 1 ? '0' + dateObj.getHours().toString() : dateObj.getHours().toString();
	// 	const minutes = dateObj.getMinutes().toString().length === 1 ? '0' + dateObj.getMinutes().toString() : dateObj.getMinutes().toString();
	// 	this.setState({
	// 		date: value,
	// 		time: `${hours}:${minutes}:00`
	// 	})
	// }

	render() {

		const visitText = this.props.visits && this.props.visits.length ? `(${this.props.visits.length} ${this.props.visits.length === 1 ? 'Visit' : 'Visits'})` : '';

		return (
			<React.Fragment>
				{this.props.modalOpen ? (
					<Modal title="Visit Added" active={this.state.modalOpen} handleClose={this.props.onModalClose}>
						<Form
							success={this.props.activitySuccess}
							failure={!this.props.activitySuccess}
							item="visit"
							handleCancel={this.props.onModalClose}
							handleReset={this.props.onModalReset}
						/>
					</Modal>
				) : null}
				<div className="u-margin-bottom-regular u-margin-bottom-large@md">
					<h2 className="c-type-delta u-margin-bottom-small">Add Visit</h2>
					<form onSubmit={(e) => this.handleSubmit(e)}>
						<div className="o-box o-box--spacing-small o-box--spacing-regular@md c-box c-box--white c-box--shadow c-box--rounded">
							{ this.props.contacts.constructor === Array || Object.getOwnPropertyNames(this.props.contacts).length === 0 ? (
								<div className="u-align-center">
									<h2 className="c-type-delta is-error">To register visits, you must first register a dealership contact</h2>
									<h3 className="c-type-foxtrot is-error">You can do this back in the "Overview" panel</h3>
								</div>
							) : (
								<div className="o-layout o-layout--spacing-regular">
									<div className="o-layout__item u-width-12/12 u-width-6/12@sm3 u-width-4/12@md3 u-margin-bottom-small u-margin-bottom-regular@md">
										<Input name="visited" type="select" label="Visited*" required valueMissingMsg="Who did you visit?" handleChange={(value) => this.updateState('visited', value)} value={this.state.visited}>
											<option value="" key={Math.random()}></option>
											{this.renderSelectOptions(this.contactsList())}
										</Input>
									</div>
									<div className="o-layout__item u-width-12/12 u-width-6/12@sm3 u-width-4/12@md3 u-margin-bottom-small u-margin-bottom-regular@md">
										<Input name="contact-method" label="Contact Method*" required valueMissingMsg="How did you contact the dealer?" type="select" value={this.state.contactMethod} handleChange={(value) => this.updateState('contactMethod', value)}>
											<option value="" key={Math.random()}></option>
											{this.renderSelectOptions(this.props.contactTypes)}
										</Input>
									</div>
									<div className="o-layout__item u-width-12/12 u-width-6/12@sm3 u-width-4/12@md3 u-margin-bottom-small u-margin-bottom-regular@md">
										<Input name="contact-type" label="Contact Type 1*" required valueMissingMsg="What was the purpose of your visit?" type="select" value={this.state.contactType1} handleChange={(value) => this.updateState('contactType1', value)}>
											<option value="" key={Math.random()}></option>
											{this.renderSelectOptions(this.props.callTypes)}
										</Input>
									</div>
									<div className="o-layout__item u-width-12/12 u-width-6/12@sm3 u-width-4/12@md3 u-margin-bottom-small u-margin-bottom-regular@md">
										<Input name="contact-type-2" label="Contact Type 2" type="select" value={this.state.contactType2} handleChange={(value) => this.updateState('contactType2', value)}>
											<option value="" key={Math.random()}></option>
											{this.renderSelectOptions(this.props.callTypes)}
										</Input>
									</div>
									<div className="o-layout__item u-width-12/12 u-width-6/12@sm3 u-width-4/12@md3 u-margin-bottom-small u-margin-bottom-regular@md">
										<Input name="contact-type-3" label="Contact Type 3" type="select" value={this.state.contactType3} handleChange={(value) => this.updateState('contactType3', value)}>
											<option value="" key={Math.random()}></option>
											{this.renderSelectOptions(this.props.callTypes)}
										</Input>
									</div>
									<div className="o-layout__item u-width-12/12 u-margin-bottom-regular">
										<Input name="comments" label="Comments*" type="textarea" required valueMissingMsg="What happened during the meeting?" handleChange={(value) => this.updateState('comments', value)} value={this.state.comments} />
									</div>
									<div className="o-layout__item u-width-12/12 u-align-center">
										<Button icon="plus">Add Visit</Button>
									</div>
								</div>
							)}
						</div>
					</form>
				</div>

				<StyledBox title={`Previous Visits ${visitText}`}>
					{this.props.visits && this.props.visits.length ? (
						<div className="o-box c-box c-box--white c-box--shadow c-box--rounded c-box--no-overflow">
							<table className="c-table c-table--collapse@max-md c-table--bare c-table--top">
								<thead>
									<tr>
										<th>Date</th>
										<th>Visited</th>
										<th>Type</th>
										<th className="u-width-6/12@md">Comments</th>
									</tr>
								</thead>
								<tbody>
									{this.props.visits.map((visit, i) => 
										<tr key={i}>
											<td data-label="Date">{visit.date}</td>
											<td data-label="Visited">{visit.name}</td>
											<td data-label="Type">{visit.type}</td>
											<td data-label="Comments" className="c-table__mobile--full">{visit.comments ? visit.comments : 'N/A'}</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					) : (
						<div className="o-box o-box--spacing-small c-box c-box--white c-box--shadow c-box--rounded">
							<p>{this.props.online ? "There haven't been any recorded visits to this dealer." : "Either there haven't been any recorded visits to this dealer, or the visits can't be retrieved because this device isn't connected to the internet."}</p>
						</div>
					)}
				</StyledBox>			
			</React.Fragment>
		);
	}
}

export default DealerDetailsActivity;