import React from 'react';
import PropTypes from 'prop-types';

import StatusMsg from './StatusMsg';
import SubHeader from './SubHeader';
import Button from './Button';
import MediaCollection from './MediaCollection';
import PresentationRow from './PresentationRow';

class PresentationView extends React.Component {
	static propTypes = {
		title: PropTypes.string,
		disabled: PropTypes.bool
	};

	static defaultProps = {
		disabled: false
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}
	
	render() {
		return (
			<React.Fragment>
				{!this.props.disabled ? (
					<React.Fragment>
						<SubHeader>
							<div className="o-layout o-layout--middle">
								<div className="o-layout__item u-width-grow u-width-auto@lg u-margin-bottom-small@sm2 u-margin-bottom-none@lg">
									<h2 className="c-type-charlie c-type--invert">Presentations</h2>
								</div>
								<div className="o-layout__item u-align-right u-width-auto@lg u-hide@max-lg">
									<Button iconSide="left" icon="circle-back" to='/'>Go Back</Button>
								</div>
							</div>
						</SubHeader>
						<div className="o-container">
							<div className="o-box o-box--spacing-small o-box--spacing-regular@md o-box--spacing-large@md2 o-box--spacing-vertical u-width-10/12@sm3 u-offset-1/12@sm3 u-width-12/12@md u-width-10/12@lg u-offset-1/12@lg">

								<MediaCollection disableBorder className="u-margin-bottom-large@md2">
									<PresentationRow title="Evolution Motor Finance - 2019 Performance" ppt="Evolution Motor Finance - 2019 Performance.pptx" pdf="Evolution Motor Finance - 2019 Performance.pdf" />
									<PresentationRow title="Rate for Risk – Customer Outcomes" ppt="R4R Aggregator - Customer Outcomes V1 Nov 19.pptx" pdf="R4R Aggregator - Customer Outcomes V1 Nov 19.pdf" />
									<PresentationRow title="DealerZone and Management console guide" video="https://player.vimeo.com/video/665547002" ppt="DealerZone and Management console guide.pptx"/>
									<PresentationRow title="Click Dealer" video="https://vimeo.com/649544499/c5c67856a9" />
									<PresentationRow title="Digital Finance review form" pdf="Digital Finance Review Form.pdf" />
								</MediaCollection>
								
							</div>
						</div>
					</React.Fragment>
				) : (
					<StatusMsg>
						<h2 className="c-type-alpha u-margin-bottom-small">This page isn't available offline</h2>
						<p>Unfortunately, this page isn't available without internet. Please try again once your connection is restored.</p>
					</StatusMsg>
				)}
			</React.Fragment>
		);
	}
}

export default PresentationView;
