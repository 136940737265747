import { isJSON } from './helpers';

function post(url, params) {
	return new Promise((resolve, reject) => {
		var request = new XMLHttpRequest();
		request.onreadystatechange = () => {
			if(request.readyState === 4) {
				if(request.status === 200) {
					let response;
					if(isJSON(request.responseText)) {
						response = JSON.parse(request.responseText);
					}
					else {
						response = request.responseText;
					}
					resolve(response);
				}
				else {
					reject(request.statusText);
				}
			}
		}
		request.open('post', url, true);
		request.withCredentials = true;
		request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');


		var formData = '';
		for (let param in params ) {
		    formData += `&${param}=${params[param]}`;
		}

		request.send(formData)
	});
}

export default post;
