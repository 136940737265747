import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import StyledBox from './StyledBox';
import Expander from './Expander';
import Modal from './Modal';
import Form from './Form';
import Input from './Input';
import InfoRow from './InfoRow';
import InfoRowSwitch from './InfoRowSwitch';
import Tooltip from './Tooltip';
import Switch from './Switch';

import { addDealerContact, editDealerContact } from './../api/dealerWrite';

class DealerContacts extends React.Component {

	static propTypes = {
		credentials: PropTypes.object,
		handleContactAdd: PropTypes.func,
		handleContactDelete: PropTypes.func,
		handleContactEdit: PropTypes.func
	};

	defaultNewContactState = {
		name: '',
		position: '',
		email: '',
		telephone: '',
		evo_emails: '0'
	}

	constructor(props) {
		super(props);
		this.state = {
			addContactModalOpen: false,
			addContactLoading: false,
			addContactSuccess: false,
			addContactFailure: false,
			contacts: {...props.contacts},
			newContact: this.defaultNewContactState
		}
	}

	openModal = () => {
		this.setState({
			addContactModalOpen: true
		});
	}

	resetModal = () => {
		this.setState({
			addContactFailure: false,
			addContactLoading: false
		})
	}

	componentDidUpdate(prevProps) {
		if(this.props !== prevProps)
		this.setState({
			contacts: {...this.props.contacts}
		})
	}

	closeModal = (prompt) => {
		const close = () => {
			this.setState({
				addContactModalOpen: false,
				addContactLoading: false,
				addContactSuccess: false,
				addContactFailure: false
			});
		}
		if(prompt) {
			if(window.confirm("Are you sure you wish to cancel?")) {
				close();
			}
		}
		else {
			close();
		}
	}

	submitNewContact = () => {
		const newContact = {...this.state.newContact};
		newContact['temp_contact_id'] = `temp-contact-${Math.floor(100000 + Math.random() * 900000)}`;
		this.setState({
			newContact,
			addContactLoading: true
		});
		addDealerContact(
			this.props.credentials,
			this.state.newContact.name,
			this.state.newContact.position,
			this.state.newContact.email,
			this.state.newContact.telephone,
			this.props.iceId,
			this.state.newContact.evo_emails,
			newContact.temp_contact_id
		)
		.then((contactId) => {
			const newContact = {...this.state.newContact};
			newContact['id'] = contactId || null;
			this.props.handleContactAdd(newContact);
			this.setState({
				newContact: this.defaultNewContactState,
				addContactLoading: false,
				addContactSuccess: true
			});
		})
		.catch(() => {
			const newContact = {...this.state.newContact};
			this.props.handleContactAdd(newContact);
			this.setState({
				newContact: this.defaultNewContactState,
				addContactLoading: false,
				addContactSuccess: true
			});
		})
	}

	updateExistingValue = (key, value, contact) => {
		const contacts = {...this.state.contacts};
		contacts[contact][key] = value;
		this.setState({
			contacts:  contacts
		}, () => {
			this.props.handleContactEdit(key, value, contact)
			editDealerContact(this.props.credentials, this.state.contacts[contact])
		})
	}

	updateNewValue = (key, value) => {
		const newContact = {...this.state.newContact};
		newContact[key] = value;
		this.setState({
			newContact
		})
	}

	renderPositionSelectOptions = () => {
		const options = [];
		if(this.props.contactPositions) {
			if(this.props.contactPositions.length) {
				this.props.contactPositions.map((item, i) => {
					options.push(<option key={i} value={item}>{item}</option>);
				})
			}
			else {
				Object.keys(this.props.contactPositions).map((key, i) => {
					options.push(<option key={i} value={this.props.contactPositions[key]}>{key}</option>);
				})
			}
		}
		return options;
	}

	handleContactDelete = (id, name) => {
		if(window.confirm("Are you sure you wish to delete this contact?")) {
			this.props.handleContactDelete(id, name);
		}
	}

	render() {
		return (
			<React.Fragment>
				<h2 className="c-type-delta u-margin-bottom-small">Contacts</h2>
				{Object.keys(this.state.contacts).length ? Object.keys(this.state.contacts).map((contact, i) =>
					<StyledBox key={i}>
						<Expander
							title={`${this.state.contacts[contact].name}${this.state.contacts[contact].position ? ', ' + this.state.contacts[contact].position : ''}`}
							disabled={!this.state.online && !this.state.contacts[contact].position}
						>
							<InfoRow key={`contact-name-${i}`} name="Name" value={this.state.contacts[contact].name} handleChange={(value) =>this.updateExistingValue('name', value, contact)} required valueMissingMsg="Please enter a contact name" />
							<InfoRow key={`contact-position-${i}`} name="Position" value={this.state.contacts[contact].position} handleChange={(value) =>this.updateExistingValue('position', value, contact)} type="select" selectOptions={this.props.contactPositions} required valueMissingMsg="Please enter a contact position" />
							<InfoRow key={`contact-email-${i}`} name="Email" type="email" typeMismatchMsg="Please enter a valid email address" value={this.state.contacts[contact].email} handleChange={(value) =>this.updateExistingValue('email', value, contact)} />
							<InfoRow key={`contact-number-${i}`} name="Contact Number" value={this.state.contacts[contact].telephone} handleChange={(value) =>this.updateExistingValue('telephone', value, contact)} pattern="^(01|02|03|07)[0-9]{9}" patternMismatchMsg="Please enter a contact number with 11 numbers which begins with either 01, 02, 03 or 07"/>
							<InfoRowSwitch 
								key={`contact-evo-email-${i}`}
								name="Evo Emails"
								value={this.state.contacts[contact].evo_emails}
								handleChange={(value) => this.updateExistingValue('evo_emails', value === '0' ? '1' : '0', contact)}
								active={this.state.contacts[contact].evo_emails === '1'}
								tooltip={() => <Tooltip message="Determines if the contact receives decision emails when a deal is accepted, declined, etc" small align="left"	/>}
							/>
							<div className="o-box o-box--spacing-small o-box--spacing-regular@md u-align-center">
								<Button state="error" handleClick={() => this.handleContactDelete(this.state.contacts[contact].id || this.state.contacts[contact].temp_contact_id, this.state.contacts[contact].name)}>Delete</Button>
							</div>
						</Expander>
					</StyledBox>
				) : <p>This dealership doesn't have any contacts.</p>}
				<div className="u-align-center u-margin-bottom-regular">
					<Button icon="plus" handleClick={this.openModal}>Add Contact</Button>
				</div>
				{this.state.addContactModalOpen ? (
					<Modal title="Add Contact" active={this.state.addContactModalOpen} handleClose={() => this.closeModal(this.state.addContactSuccess ? false : true)} showCloseButton={false} showCloseIcon={!this.state.addContactLoading}>
						<Form
							handleSubmit={this.submitNewContact}
							handleCancel={() => this.closeModal(this.state.addContactSuccess ? false : true)}
							handleReset={this.resetModal}
							loading={this.state.addContactLoading}
							success={this.state.addContactSuccess}
							failure={this.state.addContactFailure}
							item="contact"
						>
							<div className="o-layout o-layout--spacing-large">
								<div className="o-layout__item u-width-12/12 u-width-6/12@sm3">
									<Input
										label="Name*"
										className="u-margin-bottom-small"
										name="name"
										required
										valueMissingMsg="Please enter a contact name"
										handleChange={(value) =>this.updateNewValue('name', value)}
										value={this.state.newContact.name}
									/>
									<Input
										label="Position*"
										className="u-margin-bottom-small"
										name="position"
										type="select"
										handleChange={(value) =>this.updateNewValue('position', value)}
										value={this.state.newContact.position}
										required
										valueMissingMsg="Please specify a position"
									>
										<option value="" key={Math.random()}></option>
										{this.renderPositionSelectOptions()}
									</Input>
								</div>
								<div className="o-layout__item u-width-12/12 u-width-6/12@sm3">
									<Input
										label="Email Address"
										className="u-margin-bottom-small"
										name="email"
										type="email"
										typeMismatchMsg="Please enter a valid email address"
										handleChange={(value) =>this.updateNewValue('email', value)}
										value={this.state.newContact.email}
									/>
									<Input
										label="Contact Number"
										className="u-margin-bottom-small"
										name="phone"
										type="tel"
										pattern="^(01|02|03|07)[0-9]{9}"
										patternMismatchMsg="Please enter a contact number with 11 numbers which begins with either 01, 02, 03 or 07"
										handleChange={(value) => this.updateNewValue('telephone', value)}
										value={this.state.newContact.telephone}
									/>
								</div>
								<div className="o-layout__item u-12/12">
									<Switch
										label="Evo Emails"
										name="evo_emails"
										handleClick={() =>this.updateNewValue('evo_emails', this.state.newContact.evo_emails === '0' ? '1' : '0' )}
										tooltip="Determines if the contact receives decision emails when a deal is accepted, declined, etc"
										active={this.state.newContact.evo_emails === '1'}
									/>
								</div>
							</div>
						</Form>
					</Modal>
				) : null}
			</React.Fragment>
		);
	}
}

export default DealerContacts;
