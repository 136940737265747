import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Badge from './Badge';
import Button from './Button';
import Video from './Video';

class VideoRow extends Component {

	static propTypes = {
		title: PropTypes.string,
		description: PropTypes.string,
		copyLink: PropTypes.string,
		videoId: PropTypes.string,
		localVideoId: PropTypes.string,
		new: PropTypes.bool
	}

	constructor(props) {
		super(props);
		this.state = {
			linkCopy: false
		}
	}

	copyLinkElem() {

	}

	copyLink() {
		if(!this.state.linkCopy) {
			this.setState({
				linkCopy: true
			})

			const dummy = document.createElement("textarea");
			dummy.style.opacity = 0;
			dummy.setAttribute("id", "dummy_id");
			dummy.value = this.props.copyLink; 
			document.body.appendChild(dummy);

			// Copying on iOS is a bit hacky so we have to feature detect iOS
			if(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
				const range = document.createRange();
				range.selectNode(dummy);
				var s = window.getSelection();
				s.removeAllRanges();
				s.addRange(range);
				document.execCommand('copy');
			}
			else {
				dummy.select();
				document.execCommand("copy");
			}
			document.body.removeChild(dummy);

			setTimeout(() => {
				this.setState({
					linkCopy: false
				})
			}, 5000)	
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="o-layout__item u-width-12/12 u-width-6/12@md u-width-7/12@lg u-margin-bottom-regular u-margin-bottom-large@md u-margin-bottom-huge@lg c-video-row">
					{this.props.videoId ? (
						<Video id={this.props.videoId} />
					) : (
						<video controls className="u-width-12/12" poster={`/downloads/videos/${this.props.localVideoId}.jpg`}>
							<source src={`/downloads/videos/${this.props.localVideoId}.mp4`} type="video/mp4"></source>
						</video>
					)}
				</div>
				<div className="o-layout__item u-width-12/12 u-width-6/12@md u-width-5/12@lg u-margin-bottom-large u-margin-bottom-huge@lg c-video-row">
					<h3 className="c-type-delta u-margin-bottom-tiny">
						{this.props.title}
						{this.props.new ? (<Badge>New</Badge>) : null}
					</h3>
					<p>{this.props.description}</p>
					{this.props.copyLink ? (
						<React.Fragment>
							<hr />
							<Button state={this.state.linkCopy ? 'positive' : ''} iconSide="right" icon={this.state.linkCopy ? 'circle-tick' : 'play'} handleClick={() => this.copyLink()}>{!this.state.linkCopy ? 'Copy Link' : 'Link Copied'}</Button>
						</React.Fragment>
					) : null}
				</div>
			</React.Fragment>
		)
	}
}

export default VideoRow;