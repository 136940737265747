import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';
import Input from './Input';

import { formatMoney } from '../modules/helpers';

class InfoRow extends React.Component {
	static propTypes = {
		name: PropTypes.string.isRequired,
		value: PropTypes.string,
		readOnly: PropTypes.bool,
		handleChange: PropTypes.func,
		tooltip: PropTypes.func,
		type: PropTypes.string,
		selectOptions: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.object
		])
	};

	constructor(props) {
		super(props);
		this.state = {
			editMode: false,
			localValue: '',
			inputError: false
		}
		this.elem = React.createRef();
	}

	toggleEdit = () => {
		this.setState({
			editMode: !this.state.editMode
		}, () => {
			if(this.state.editMode) {
				if(this.props.type === 'select') {
					this.elem.current.querySelector('select').focus();
				}
				else {
					this.elem.current.querySelector('input').focus();
				}
			}
		});
	}

	cloneValue = () => {
		// If dropdown, because API handles values unconventially, we need extra logic to account for this
		if(this.props.selectOptions) {
			if(this.props.type === 'select' && this.props.selectOptions[this.props.value]) {
				this.setState({
					localValue: this.props.selectOptions[this.props.value] || ''
				})
			}
			// Carry on as before
			else {
				this.setState({
					localValue: this.props.value || ''
				})
			}
		}
		// Carry on as before
		else {
			this.setState({
				localValue: this.props.value || ''
			})
		}
	}

	handleLocalChange = (value) => {
		this.setState({
			localValue: value
		})
	}

	handleKeyPress = (e, func) => {
		if(e.which === 12) {
			func();
		}
	}

	componentDidMount() {
		this.cloneValue();
	}

	componentDidUpdate(prevProps) {
		if(prevProps.value !== this.props.value) {
			this.cloneValue();
		}
	}

	confirmEdit = () => {
		this.props.handleChange(this.state.localValue);
		this.setState({
			editMode: false
		})
	}

	cancelEdit = () => {
		this.cloneValue();
		this.setState({
			editMode: false,
			inputError: false
		})
	}

	handleInvalid = () => {
		if(!this.state.inputError) {
			this.setState({
				inputError: true
			})	
		}
	}

	handleValid = () => {
		this.setState({
			inputError: false
		})	
	}

	valueFormatter = () => {
		if(this.props.type === 'currency' && this.state.localValue.length !== 0) {
			return '£ ' + formatMoney(this.state.localValue);
		}
		return this.state.localValue;
	}

	renderSelectOptions = () => {
		const options = [];
		// Check if we're dealing with an array or a key/value pair
		if(this.props.selectOptions.length) {
			this.props.selectOptions.map((item, i) => {
				options.push(<option key={i} value={item}>{item}</option>);
				return options;
			})
		}
		else {
			Object.keys(this.props.selectOptions).map((key, i) => {
				options.push(<option key={i} value={this.props.selectOptions[key]}>{key}</option>);
				return options;
			})
		}
		return options;
	}

	render() {
		return (
			<div className={`c-info-row ${!this.state.editMode ? 'c-info-row--static-mode' : ''} ${this.props.readOnly ? 'c-info-row--read-only' : ''}`} ref={this.elem}>
				<div className="c-info-row__label">
					{this.props.tooltip ? (
						<React.Fragment>
							<div className="u-margin-right-tiny">{this.props.name}</div>
							{this.props.tooltip()}
						</React.Fragment>
					) : this.props.name}
				</div>
				<div className="c-info-row__wrapper">
					<Input
						disabled={!this.state.editMode}
						value={this.valueFormatter()}
						handleChange={(value) => this.handleLocalChange(value)}
						handleInvalid={this.handleInvalid}
						handleValid={this.handleValid}
						name={`${Math.random()}-${this.props.name.replace(/\s+/g, '-').toLowerCase()}`}
						type={this.props.type}
						className="c-info-row__input"
						valueMissingMsg={this.props.valueMissingMsg}
						typeMismatchMsg={this.props.typeMismatchMsg}
						patternMismatchMsg={this.props.patternMismatchMsg}
						pattern={this.props.pattern ? this.props.pattern : undefined}
						placeholder={this.props.placeholder ? this.props.placeholder : undefined}
						error={this.state.inputError}
						required={this.props.required}
						calendarFloat={this.props.type === 'date'}
						calendarDateFormat='YYYY-MM-DD'
						showCalendarControls={this.props.type === 'date' && this.state.editMode}
						showSelectControls={this.props.type === 'select' && this.state.editMode}
					>
						{this.props.type === 'select' && this.props.selectOptions ? (
							<React.Fragment>
								<option value="" key={Math.random()}></option>
								{this.renderSelectOptions()}
							</React.Fragment>
						) : null}
					</Input>
					<div className="c-info-row__controls">
						{!this.props.readOnly && !this.state.editMode ? (
							<Icon
								use="edit"
								className="c-info-row__edit-icon"
								tabIndex="0"
								handleClick={this.toggleEdit}
								handleKeyPress={(e) => this.handleKeyPress(e, this.toggleEdit())}
							/>
						) : null}
						{this.state.editMode ? (
							<React.Fragment>
								<Icon
									use="circle-tick"
									className={`c-info-row__control c-info-row__control--accept u-margin-right-tiny u-margin-right-small@sm2 ${this.state.inputError ? 'is-disabled' : ''}`}
									tabIndex="0"
									handleClick={!this.state.inputError ? this.confirmEdit : () => {}}
									handleKeyPress={!this.state.inputError ? (e) => this.handleKeyPress(e, this.confirmEdit()) : () => {}}
								/>
								<Icon
									use="circle-undo"
									className="c-info-row__control c-info-row__control--cancel"
									tabIndex="0"
									handleClick={this.cancelEdit}
									handleKeyPress={(e) => this.handleKeyPress(e, this.cancelEdit())}
								/>
							</React.Fragment>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}

export default InfoRow;