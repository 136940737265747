export function storeLocalStorage(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
}

export function parseLocalStorage(key) {
	return JSON.parse(localStorage.getItem(key));
}

export function removeLocalStorage(key) {
	if(typeof key === 'object') {
		for(let i = 0; i < key.length; i++) {
			localStorage.removeItem(key[i]);
		}
	}
	else {
		localStorage.removeItem(key);
	}
}

// Checks to see if any LocalStorage keys match the passed string
export function searchLocalStorage(string) {
	for(let i = 0; i < localStorage.length; i++){
		if(localStorage.key(i).includes(string)) {
			return true;
		}
	}
	return false;
}