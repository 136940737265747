import React from 'react';
import PropTypes from 'prop-types';

import SubHeader from './SubHeader';
import Icon from './Icon';
import Tabs from './Tabs';
import Tab from './Tab'
import DealerDetailsOverview from './DealerDetailsOverview'
import DealerDetailsActivity from './DealerDetailsActivity'
import FrameView from './FrameView'
import Loading from './Loading'

import { getDealerBasicDetails, getDealerCallList, getDealerNotes, getDealerContacts } from './../api/dealer';
import { updateDealerData, deleteDealerContact, addActivityVisit } from './../api/dealerWrite';
import { storeLocalStorage, parseLocalStorage } from './../modules/handleStorage';

class DealerDetails extends React.Component {

	// COMPONENT FUNCTIONS
	//------------------------------------------------------------------------------------------------

	static propTypes = {
		credentials: PropTypes.object,
		online: PropTypes.bool,
		syncPending: PropTypes.bool,
		contacts: PropTypes.array,
		contactPositions: PropTypes.array,
		contactTypes: PropTypes.array,
		callTypes: PropTypes.array
	};

	constructor(props) {
		super(props);
		this.state = {
			view: 'overview',
			details: [],
			notes: '',
			callList: [],
			contacts: {},
			frameUrl: null,
			name: '',
			showActivityModal: false,
			activitySuccess: null
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		// Wait a few seconds to give syncing a chance to complete if one is pending, so we get the most up to date info
		setTimeout(() => {
			this.getDealerData();
		}, this.props.syncPending ? 4000 : 0);
		this.setDealerName();
	}

	componentDidUpdate(prevProps) {
		// If we're on a temp dealer, switch to it's new page with the correct ICE_ID on sync
		if(prevProps.tempMappings !== this.props.tempMappings && this.props.tempMappings) {
			const mappings = this.props.tempMappings.dealerMappings ? this.props.tempMappings.dealerMappings : this.props.tempMappings;
			if(mappings[this.props.match.params.id]) {
				this.props.history.push(this.props.location.pathname.replace(this.props.match.params.id, mappings[this.props.match.params.id] + this.props.location.search));
			}
		}
		// If we're switching dealers, update dealer data & name
		if(prevProps.match.params.id !== this.props.match.params.id) {
			window.scrollTo(0, 0);
			this.getDealerData();
			this.setDealerName();
		}
		// If dealers are being injected in for the first time, update name
		if(!prevProps.dealers.length && this.props.dealers.length) {
			this.setDealerName();
		}
		// If we're transitioning from offline to online and we've finished syncing, grab dealer data
		if(prevProps.online !== this.props.online && this.props.online) {
			// Wait a few seconds to give syncing a chance to complete, so we get the most up to date info
			setTimeout(() => {
				this.getDealerData();
			}, this.props.syncPending ? 4000 : 0);
		}
	}

	// DATA FETCHING FUNCTIONS
	//------------------------------------------------------------------------------------------------

	getDealerData = () => {
		if(this.props.credentials) {
			// Only attempt to grab this data if online, as we don't cache it
			if(this.props.online) {
				getDealerBasicDetails(this.props.match.params.id, this.props.credentials).then(response => {
					if(typeof response === 'object') {
						// Parse field data into a more easily readable format
						const fieldData = {};
						response.map(segment => {
							for(let field in segment.fields) {
								fieldData[segment.fields[field].field] = segment.fields[field].value;
							}
							return fieldData;
						})

						this.setState({
							details: response,
							fieldData
						})
					}
				})
				getDealerNotes(this.props.match.params.id, this.props.credentials).then(response => {
					this.setState({
						notes: response
					})
				})
			}
			getDealerCallList(this.props.match.params.id, this.props.credentials).then(response => {
				// Remove labels from API response
				if(typeof response === 'object' && response.length && response[0].date === "DATE") {
					response.splice(0, 1);
				}
				this.setState({
					callList: response
				})
			})
			// If we have cached contacts, use those
			getDealerContacts(this.props.match.params.id, this.props.credentials)
			.then(response => {
				const contacts = {...response};
				// If offline, get the list of master list contacts and see if some of them are missing
				// from our getDealerContacts list. This means if we add a new contact whilst offline,
				// there won't be a bug where only that one is displaying
				if(!this.props.online) {
					// Filter out contacts from our master list
					this.props.contacts.filter((contact) => {
						if(contact.ice_id === this.props.match.params.id) {
							if(!contacts[contact.contact_name]) {
								contacts[contact.contact_name] = {
									name: contact.contact_name,
									id: contact.contact_id
								}
							}
						}
						return true;
					});
				}
				this.setState({
					contacts: contacts
				})
			})
			// Otherwise filter out names from our master list, which is also cached
			.catch(() => {
				const formattedContacts = {}
				this.props.contacts.filter((contact) => {
					if(contact.ice_id === this.props.match.params.id) {
						formattedContacts[contact.contact_name] = {
							name: contact.contact_name,
							id: contact.contact_id
						}
					}
					return true;
				});
				this.setState({
					contacts: formattedContacts
				})
			})
		}
	}


	// DATA SETTING FUNCTIONS
	//------------------------------------------------------------------------------------------------

	updateFieldData = (key, value, upload = true) => {
		const fieldData = {...this.state.fieldData};
		fieldData[key] = value;
		// Run some checks on certain values before we send them as the API expects some values to be odd formats
		const checkedValue = (value) => {
			if(value === 'Yes') {
				return 1
			}
			else if(value === 'No') {
				return 0
			}
			else return value;
		}
		this.setState({
			fieldData:  fieldData
		}, () => {
			if(upload) {
				updateDealerData(this.props.credentials, this.props.match.params.id, key, checkedValue(value))
			}
		})
	}

	// Decides the best place to get dealer name from depending on online status,
	// as we only want to use the second filter method if we have no other choices
	// as it's more taxing
	setDealerName = () => {
		if(this.props.location.search) {
			this.setState({
				name: this.props.location.search.replace('?name=', '').replace(/%20/g, ' ')
			})
		}
		else if(this.props.dealers.length) {
			const currentDealer = this.props.dealers.filter(dealer => dealer.iceId === this.props.match.params.id);
			this.setState({
				name: currentDealer.length ? currentDealer[0].name : 'Unknown Dealer'
			})
		}
	}

	// Updates list of contacts
	addContact = (contact) => {
		const contacts = {...this.state.contacts}
		contacts[contact.name] = contact;
		this.setState({
			contacts
		});
		// Update cached list
		const cached = parseLocalStorage('contacts') || {};
		if(cached[this.props.match.params.id]) {
			cached[this.props.match.params.id][contact.name] = contact;	
		}
		else {
			cached[this.props.match.params.id] = {
				[contact.name]: contact
			}
		}		
		storeLocalStorage('contacts', cached);
	}

	updateContact = (key, value, contact) => {
		// Update contact data
		const newState = JSON.parse(JSON.stringify(this.state))
		newState.contacts[contact][key] = value;
		const matchingContact = newState.contacts[contact];
		// Update any visits attributed to that contact if name has been changed
		const relevantVisits = newState.callList.filter((visit) => {
			if(matchingContact.id) {
				return visit.contact_id === matchingContact.id;
			}
			return visit.contact_id === matchingContact.temp_contact_id;
		});
		for(let i = 0; i < relevantVisits.length; i++) {
			relevantVisits[i].name = matchingContact.name;
		}
		this.setState(newState);
		// Update cached data
		const cached = parseLocalStorage('contacts');
		cached[this.props.match.params.id][contact][key] = value;
		storeLocalStorage('activity', {[this.props.match.params.id] : newState.callList});
		storeLocalStorage('contacts', cached);
	}

	removeContact = (id, name) => {
		const handleContactDelete = (id, name) => {
			const newState = JSON.parse(JSON.stringify(this.state));
			for(let contact in newState.contacts) {
				if(newState.contacts[contact].name === name) {
					delete newState.contacts[contact];
				}
			}
			this.setState(newState);
			// Update cached list
			const cached = parseLocalStorage('contacts');
			cached[this.props.match.params.id] = newState.contacts[this.props.match.params.id];
			storeLocalStorage('contacts', cached);
			this.props.removeContact(id);
		}
		deleteDealerContact(this.props.credentials, id)
		.then(() => handleContactDelete(id, name))
		.catch(() => handleContactDelete(id, name))
	}

	addVisit = (visit) => {
		const getContactName = (id) => {
			for(let contact in this.state.contacts) {
				// We aren't type checking here on purpose, as API inconsistencies mean we don't always get data in the same format.
				// eslint-disable-next-line
				if(this.state.contacts[contact].id == id || this.state.contacts[contact].temp_contact_id === id) {
					return this.state.contacts[contact].name;
				}
			}
			return 'N/A';
		}
		const processContactTypes = (type1, type2, type3) => {
			const type2Value = type2.length ? ', ' + type2 : '';
			const type3Value = type3.length ? ', ' + type3 : '';
			return type1 + type2Value + type3Value;
		}
		addActivityVisit(this.props.credentials, visit, this.props.match.params.id)
		.catch(error => {
			if(error) {
				console.log(error);
			}
		})
		const newState = {...this.state};
		const newVisit = {
			comments: visit.comments,
			date: visit.altDate,
			name: getContactName(visit.visited),
			type: processContactTypes(visit.contactType1, visit.contactType2, visit.contactType3),
			contact_id: visit.visited
		}
		newState.callList.unshift(newVisit)
		newState.showActivityModal = true;
		newState.activitySuccess = true;
		this.setState(newState);
		// Updated cached visits
		const cached = parseLocalStorage('activity') || {};
		if(cached[this.props.match.params.id]) {
			const firstItem = cached[this.props.match.params.id].shift();
			cached[this.props.match.params.id].unshift(newVisit);
			cached[this.props.match.params.id].unshift(firstItem);
		}
		else {
			cached[this.props.match.params.id] = [newVisit]
		}
		storeLocalStorage('activity', cached);
	}

	// VIEW RENDERING FUNCTIONS
	//------------------------------------------------------------------------------------------------

	tabHandler = () => {
		if(this.state.view === 'overview') {
			return (
				<div className="o-container o-container--fluid">
					<div className="o-box o-box--spacing-small o-box--spacing-regular@md o-box--spacing-vertical">
						<DealerDetailsOverview
							name={this.state.name}
							details={this.state.details}
							notes={this.state.notes}
							contacts={this.state.contacts}
							handleContactAdd={this.addContact}
							handleContactEdit={this.updateContact}
							handleContactDelete={this.removeContact}
							contactPositions={this.props.contactPositions}
							setFrameView={this.setFrameView}
							credentials={this.props.credentials}
							online={this.props.online}
							fieldData={this.state.fieldData}
							updateFieldData={this.updateFieldData}
							match={this.props.match}
						/>
					</div>
				</div>
			)
		}
		else if(this.state.view === 'activity') {
			return (
				<div className="o-container o-container--fluid">
					<div className="o-box o-box--spacing-small o-box--spacing-regular@md o-box--spacing-vertical">
						<DealerDetailsActivity
							visits={this.state.callList}
							contacts={this.state.contacts}
							contactTypes={this.props.contactTypes}
							callTypes={this.props.callTypes}
							online={this.props.online}
							handleNewVisit={this.addVisit}
							modalOpen = {this.state.showActivityModal}
							onModalClose = {() => this.setState({showActivityModal: false})}
							onModalReset = {() => this.setState({showActivityModal: false, activitySuccess: null})}
							activitySuccess = {this.state.activitySuccess}
						/>
					</div>
				</div>
			)
		}
		else if(this.state.view === 'frame' && this.state.frameUrl) {
			return (
				<FrameView url={this.state.frameUrl} showBorder={false} />
			)
		}
		else {
			return (
				<div>There seems to have been an error</div>
			)
		}
	}

	setFrameView = (url) => {
		this.setState({
			view: 'frame',
			frameUrl: url
		});
	}

	toggleView = (view) => {
		this.setState({
			view: view
		});
	}

	goBack = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if(this.state.view === 'frame') {
			this.setState({
				view: 'overview'
			})
		}
		else {
			this.props.history.push('/dealer-list')
		}
	}

	viewHandler = () => {
		return (
			<React.Fragment>
				<SubHeader>
					<div className="o-layout o-layout--middle">
						<div className="o-layout__item">
							<div className="o-media o-media--spacing-small">
								<div className="o-media__image">
									<Icon use="circle-back" color="white" handleClick={(e) => this.goBack(e)}/>
								</div>
								<div className="o-media__body">
									<h2 className="c-type-charlie c-type--invert">{this.state.view === 'frame' ? 'Back to overview' : this.state.name}</h2>
								</div>
							</div>
						</div>
					</div>
				</SubHeader>
				{this.state.view !== 'frame' ? (
					<SubHeader removePadding fixContentMargins>
						<Tabs collapse="sm2" className="u-margin-bottom-small u-margin-bottom-none@sm2">
							<Tab active={this.state.view === 'overview'} handleClick={() => this.toggleView('overview')}>Overview</Tab>
							<Tab active={this.state.view === 'activity'} handleClick={() => this.toggleView('activity')}>
								Activity ({this.state.callList.length + ' '}{this.state.callList.length === 1 ? 'Visit' : 'Visits'})
							</Tab>
						</Tabs>
					</SubHeader>
				) : null}
				{this.tabHandler()}
			</React.Fragment>
		)
	}

	render() {
		if(this.props.online) {
			if(this.state.details.length && this.props.dealers.length) {
				return this.viewHandler();
			}
			else return <Loading fullScreen />
		}
		else {
			return this.viewHandler();
		}
	}

}

export default DealerDetails;
