import React from 'react';
import PropTypes from 'prop-types';

import Calendar from './Calendar';
import Icon from './Icon';
import Tooltip from './Tooltip';

import moment from 'moment';

export default class Input extends React.Component {
	static propTypes = {
		calendarFloat: PropTypes.bool,
		calendarDateFormat: PropTypes.string,
		className: PropTypes.string,
		disabled: PropTypes.bool,
		disabledInvisible: PropTypes.bool,
		error: PropTypes.bool,
		handleChange: PropTypes.func,
		handleDateChange: PropTypes.func,
		handleInvalid: PropTypes.func,
		handleValid: PropTypes.func,
		infoRowMix: PropTypes.bool,
		invert: PropTypes.bool,
		label: PropTypes.string,
		leftLabel: PropTypes.bool,
		maxLength: PropTypes.string,
		name: PropTypes.string.isRequired,
		pattern: PropTypes.string,
		placeholder: PropTypes.string,
		required: PropTypes.bool,
		row: PropTypes.string,
		standardTextColor: PropTypes.bool,
		type: PropTypes.string,
		value: PropTypes.string,
		autocomplete: PropTypes.string,
		tooltip: PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.string
		]),
		showCalendarControls: PropTypes.bool,
		showSelectControls: PropTypes.bool,
		showTime: PropTypes.bool,
		step: PropTypes.string
	};

	static defaultProps = {
		showCalendarControls: true,
		showSelectControls: true,
		calendarDateFormat: 'Do MMMM YYYY'
	}

	constructor(props) {
		super(props);
		this.input = React.createRef();
		this.state = {
			error: false,
			errorMsg: '',
			showCalendar: false,
			charactersRemaining: props.maxLength || null,
			localDate: null
		}
		if(props.error) {
			this.state.error = true;
		}
	}

	inputClasses = () => {
		let classes = 'c-input';
		if(this.props.invert) {
			classes += ' c-input--invert';
		}
		if(this.props.leftLabel) {
			classes += ' c-input--left-label';
		}
		if(this.props.className) {
			classes += ' ' + this.props.className;
		}
		if(this.state.error) {
			classes += ' is-error';
		}
		if(this.props.type === 'date') {
			classes += ' c-input--calendar c-input--suffix';
		}
		if(this.props.disabled) {
			classes += ' is-disabled';
		}
		if(this.props.className) {
			classes += ` ${this.props.className}`;
		}
		if(this.props.standardTextColor) {
			classes += ' c-input--standard-text-color';
		}
		return classes;
	}

	handleChange = (value, date) => {
		if(this.input.current.checkValidity()) {
			this.setState({
				error: false,
				errorMsg: ''
			}, () => {
				if(this.props.handleValid) {
					this.props.handleValid();
				}
			})
		}
		if(this.props.handleChange) {
			this.props.handleChange(value, date);
		}
	}

	handleDateChange = (date) => {
		this.toggleCalendar();
		const formattedDate = moment(date).format(this.props.calendarDateFormat)
		this.input.current.value = formattedDate; 
		this.handleChange(formattedDate, date);
	}

	handleTimeChange = (date) => {
		const formattedDate = moment(date).format(this.props.calendarDateFormat)
		this.input.current.value = formattedDate; 
		this.handleChange(formattedDate, date);
	}

	toggleCalendar = () => {
		this.setState({
			showCalendar: !this.state.showCalendar
		})
	}

	handleInvalid = (e) => {
		e.preventDefault();
		for(var state in this.input.current.validity) {
			if(this.input.current.validity[state]) {
				this.setState({
					error: true,
					errorMsg: this.props[state + 'Msg'] || 'There was an error'
				})
				break;
			}
		}
		if(this.props.handleInvalid) {
			this.props.handleInvalid();
		}
	}

	renderType = () => {
		if(this.props.type === 'date' || !this.props.type) {
			return 'text'
		}
		else if(this.props.type) {
			return this.props.type;
		}
	}

	componentDidMount() {
		if(this.props.type === 'date' && !this.state.error) {
			this.parseDate(this.props.value);
		}
	}

	componentDidUpdate(prevProps) {
		// If error state is being managed externally via props, sync local error state boolean
		if(prevProps.error !== this.props.error) {
			this.setState({
				error: this.props.error
			});
		}
		if(this.props.maxLength && prevProps.value !== this.props.value) {
			this.setState({
				charactersRemaining: parseInt(this.props.maxLength) - this.input.current.value.length
			})
		}
		if(this.props.type === 'date' && this.props.value !== prevProps.value && !this.state.error) {
			this.parseDate(this.props.value);
		}
	}

	parseDate() {
		if(this.props.calendarDateFormat === 'DD/MM/YYYY') {
			this.setState({
				localDate: new Date(this.props.value.split('/')[2], this.props.value.split('/')[1] - 1, this.props.value.split('/')[0])
			});
		}
	}

	render() {
		return (
			<div className={this.inputClasses()}>
				{this.props.label ? (
					<label className="c-type-foxtrot c-input__label" htmlFor={`${this.props.name}-field`}>{this.props.label}</label>
				) : ''}
				<div className="c-input__wrapper">
					{this.props.type === 'date' && this.props.showCalendarControls ? (
						<div className="c-input__suffix" onClick={this.toggleCalendar}>
							<svg className="c-input__icon"><Icon use="calendar" /></svg>
						</div>
					) : null}
					{this.props.type === 'select' ? (
						<React.Fragment>
							<select
								className="c-input__field"
								id={`${this.props.name}-field`}
								name={this.props.name}
								onChange={(e) => this.handleChange(e.target.value)}
								onInvalid={(e) => this.handleInvalid(e)}
								value={this.props.value}
								ref={this.input}
								required={this.props.required}
								pattern={this.props.pattern}
								disabled={this.props.disabled || this.props.disabledInvisible}
								autoComplete={this.props.autocomplete}
							>
								{this.props.children}
							</select>
							{this.props.type === 'select' && this.props.showSelectControls ? (
								<Icon use="arrow" className="c-input__arrow" />
							) : null}
						</React.Fragment>
					) : null}
					{this.props.type === 'textarea' ? (
						<textarea
							className="c-input__field"
							id={`${this.props.name}-field`}
							name={this.props.name}
							placeholder={this.props.placeholder}
							onChange={(e) => this.handleChange(e.target.value)}
							onInvalid={(e) => this.handleInvalid(e)}
							value={this.props.value}
							ref={this.input}
							required={this.props.required}
							pattern={this.props.pattern}
							rows={this.props.rows || '5'}
							disabled={this.props.disabled || this.props.disabledInvisible}
							maxLength={this.props.maxLength}
							autoComplete={this.props.autocomplete}
						></textarea>
					) : null}
					{this.props.type !== 'textarea' && this.props.type !== 'select' ? (
						<input
							className="c-input__field"
							id={`${this.props.name}-field`}
							name={this.props.name}
							placeholder={this.props.placeholder}
							onChange={(e) => this.handleChange(e.target.value)}
							onInvalid={(e) => this.handleInvalid(e)}
							value={this.props.value}
							ref={this.input}
							required={this.props.required}
							type={this.renderType()}
							pattern={this.props.pattern}
							disabled={this.props.disabled || this.props.disabledInvisible}
							autoComplete={this.props.autocomplete}
							step={this.props.type === 'number' && this.props.step ? this.props.step : 'any'}
						/>
					) :  null}
				</div>
				{this.props.tooltip ? (
					<div className="c-input__tooltip">
						{typeof this.props.tooltip === 'function' ? (
							this.props.tooltip()
						) : (
							<Tooltip align="right" message={this.props.tooltip} invert={this.props.invert} small />
						)}
					</div>
				) : null}
				{this.state.error && this.state.errorMsg ? (
					<div className="c-input__text c-input__text--error">{this.state.errorMsg}</div>
				) : null}
				{this.props.maxLength ? (
					<div className="c-input__length">
						<div className="c-input__length-bar" style={{width: `${this.state.charactersRemaining / parseInt(this.props.maxLength) * 100}%`}}></div>
						<div className="c-input__length-text">	{this.state.charactersRemaining}</div>
					</div>
				) : null}
				{this.props.type === 'date' ? (
					<Calendar
						input
						active={this.state.showCalendar && this.props.showCalendarControls}
						onSelectDate={this.handleDateChange}
						onSelectTime={this.handleTimeChange}
						float={this.props.calendarFloat}
						date={this.props.value ? this.state.localDate : undefined}
						showTime={this.props.showTime}
					/>
				) : null}
			</div>
		);
	}
}
