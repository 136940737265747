import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from './Tooltip';

const Switch = (props) => {

	const handleClick = (e) => {
		e.preventDefault();
		props.handleClick();
	}

	const label = props.label ? <label onClick={(e) => handleClick(e)} className="c-type-foxtrot c-switch__label">{props.label}</label> : null;

	return (
		<div className={`c-switch ${props.active ? 'is-active' : ''} ${props.disabled ? 'is-disabled' : ''} ${props.className}`}>
			{label}
			<div className="c-switch__wrapper">
				<input type="checkbox" className="c-switch__checkbox" name={props.name} checked={props.active} disabled={props.disabled} onChange={() => {}} />
				<button className="c-switch__input" onClick={(e) => handleClick(e)}></button>
				{props.tooltip ? (
					<div className="c-switch__tooltip">
							{typeof props.tooltip === 'function' ? (
								props.tooltip()
							) : (
								<Tooltip align="left" small message={props.tooltip} />
							)}
					</div>
				) : null}
			</div>
		</div>
	)
}

Switch.propTypes = {
	active: PropTypes.bool,
	handleClick: PropTypes.func,
	label: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	tooltip: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.string
	]),
	disabled: PropTypes.bool
}

export default Switch;