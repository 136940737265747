/* eslint-disable */
// Formats money. Eg: 12000 > 12,000
export function formatMoney(n, c, d, t) {
	var c = isNaN(c = Math.abs(c)) ? 2 : c,
	d = d === undefined ? "." : d,
	t = t === undefined ? "," : t,
	s = n < 0 ? "-" : "",
	i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
	j = (j = i.length) > 3 ? j % 3 : 0;
	return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

// Checks if passed string is JSON format
export function isJSON(str) {
	try {
		JSON.parse(str);
	}
	catch (e) {
		return false;
	}
	return true;
}

// Adds a leading 0 to a value if single digit
export function zeroify(value) {
	const returnValue = value.toString();
	return returnValue.length === 1 ? '0' + returnValue : returnValue;
}