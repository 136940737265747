import React from 'react';
import PropTypes from 'prop-types';

const Tab = (props) => {

	const handleKeyPress = (e) => {
		if(e.which === 13) {
			props.handleClick();
		}
	}

	return (
		<li className={`o-layout__item c-tabs__item ${props.active ? 'is-active' : ''}`} tabIndex="0" onKeyPress={(e) => handleKeyPress(e)} onClick={props.handleClick}><div className="c-tabs__item-inner">{props.children}</div></li>
	)
}

Tab.propTypes = {
	active: PropTypes.bool,
	handleClick: PropTypes.func.isRequired
}

export default Tab;