import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import Icon from './Icon';

export default class Modal extends React.Component {
	static propTypes = {
		title: PropTypes.string,
		active: PropTypes.bool,
		handleClose: PropTypes.func.isRequired,
		size: PropTypes.string,
		showCloseButton: PropTypes.bool,
		showCloseIcon: PropTypes.bool
	};

	static defaultProps = {
		showCloseIcon: true,
		active: true
	}

	constructor(props) {
		super(props);
		this.titleWrapper = React.createRef();
	}

	componentDidMount() {
		const modalClose = this.titleWrapper.current.querySelector('.c-modal__close');
		if(modalClose) {
			modalClose.focus();
		}
		document.body.classList.add('is-fixed');
	}

	componentWillUnmount() {
		document.body.classList.remove('is-fixed');
	}

	render() {
		const size = this.props.size ? `c-modal--${this.props.size}` : '';
		const title = this.props.title ? <h1 className="c-modal__title">{this.props.title}</h1> : null;
		const closeButton = this.props.showCloseButton ? (
			<div className="o-box o-box--spacing-small u-align-center">
				<Button icon="close" iconSize="small" handleClick={this.props.handleClose}>Close</Button>
			</div>
		) : null;
		const closeIcon = this.props.showCloseIcon ? (
			<Icon className="c-modal__close" tabIndex="0" use="close" handleClick={this.props.handleClose} handleKeyPress={this.props.handleClose} />
		) : null;

		return (
			<div className={`c-modal ${size} is-active`}>
				<div className="c-modal__wrapper">
					 <div className="c-modal__title-wrapper" ref={this.titleWrapper}>
							{title}
							{closeIcon}
					 </div>
					 <div className="c-modal__box">
							<div className="c-modal__content">
								{this.props.children}
								{closeButton}
							</div>
					 </div>
				</div>
			</div>
		);
	}
}
