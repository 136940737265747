import post from '../modules/post';
import configData from '../settings.js';

export function login() {
	return new Promise((resolve, reject) => {
		post(configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=get-auth-token', {
			am_app_url: window.location.href
		})
		.then((response) => {
			if(response.error) {
				reject(response);
			}
			else if(response.success) {
				resolve(response);
			}
		})
		.catch((response) => {
			reject(response);
		})
	});
}

export function whoami(credentials) {
	return post(configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=whoami', {});
}

export function logout(credentials) {
	return new Promise((resolve, reject) => {
		post(configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=logout', {
			am_app_url: window.location.href
		})
		.then((response) => {
			if(response.error) {
				reject(response);
			}
			else {
				resolve(response);
			}
		})
	});
}
