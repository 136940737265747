import post from '../modules/post';
import getData from '../modules/getData';

import configData from '../settings.js';

export function getDealerList(credentials) {
	return getData('dealers', configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=dealerlist_structured', {}, (value) => {
		return value !== 'Unauthorised'
	});
}

export function getDealerBasicDetails(iceId, credentials) {
	return post(configData.EVOLUTION_MAIN_ENDPOINT + `/activity/f.php?f=basicdetails&dealer=${iceId}`, {});
}

export function getDealerFranchiseTypes(credentials) {
	return getData('franchiseTypes', configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=franchise_types', {}, (value) => {
		return value !== 'Unauthorised'
	});
}

export function getDealerNotes(iceId, credentials) {
	return post(configData.EVOLUTION_MAIN_ENDPOINT + `/activity/f.php?f=dealernotes&dealer=${iceId}`, {});
}

export function getAllDealerContacts(credentials) {
	return getData('contactsAll', configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=contactsall', {
		user_id: credentials.userId,
		token: credentials.token
	}, (value) => {
		return value !== 'Unauthorised'
	});
}

export function getDealerContacts(iceId, credentials) {
	return getData({
		key: 'contacts',
		subKey: iceId
	},
		configData.EVOLUTION_MAIN_ENDPOINT + `/activity/f.php?f=contactdetails&dealer=${iceId}`,
	{
	}, (value) => {
		return value !== 'Unauthorised'
	});
}

export function getDealerContactPositions(credentials) {
	return getData('contactPositions', configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=contactpositions', {
	}, (value) => {
		return value !== 'Unauthorised'
	});
}

export function getDealerContactTypes(credentials) {
	return getData('contactTypes', configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=contacttypes', {
	}, (value) => {
		return value !== 'Unauthorised'
	});
}

export function getDealerCallTypes(credentials) {
	return getData('callTypes', configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=calltypes', {
	}, (value) => {
		return value !== 'Unauthorised'
	});
}

export function getDealerCallList(iceId, credentials) {
	return getData({
		key: 'activity',
		subKey: iceId
	},
	configData.EVOLUTION_MAIN_ENDPOINT + `/activity/f.php?f=calllist&dealer=${iceId}`, {}, (value) => {
		return value !== 'Unauthorised'
	});
}
