import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const RiskClass = (risk) => {
	if(risk) {
		switch(risk.toString()) {
			case '1':
			case '2':
			case '2a':
			case '2b':
			case '3':
				return null;
			case '5': 
				return 'c-dealer-list-item--risk-bad';
			case '9': 
				return 'c-dealer-list-item--risk-worst';
			default: 
				return 'c-dealer-list-item--risk-ungraded';
		}
	}
	return 'c-dealer-list-item--risk-ungraded';
}

const DealerListItem = (props) => {
	return (
		<Link className={`o-box o-box--spacing-small c-dealer-list-item ${RiskClass(props.risk)}`} to={props.path}>
			<div className="o-layout o-layout--middle">
				<div className="o-layout__item u-width-grow">
					<h3 className="c-dealer-list-item__title">{props.name}</h3>
				</div>
				{props.risk ? (
					<div className="o-layout__item u-width-shrink">
						<div className="c-dealer-list-item__risk">
							{props.risk}
							<small className="c-dealer-list-item__risk-subtext">RISK</small>
						</div>
					</div>
				) : null}
			</div>
		</Link>
	)
}

DealerListItem.propTypes = {
	name: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	risk: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	])
}

export default DealerListItem;