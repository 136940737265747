import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';

class Video extends Component {

	static propTypes = {
		id: PropTypes.string
	};

	constructor(props) {
		super(props);
		this.state = {
			active: false
		}
	}

	componentDidMount() {
		// If not present, include YT api script
		if(!document.querySelector('.js-youtube')) {
			const tag = document.createElement('script');
			const firstScriptTag = document.getElementsByTagName('script')[0];
			tag.src = "https://www.youtube.com/iframe_api";
			tag.classList.add('js-youtube');
			firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		}
	}

	onPlayerReady(event) {
		event.target.playVideo();
	}

	initVideo() {

		const video = new window.YT.Player(`video-${this.props.id}`, {
			videoId: this.props.id,
			playerVars: {
				rel: 0
			},
			events: {
				'onReady': this.onPlayerReady
			}
		})

		this.setState({
			active: true,
			obj: video
		})
	}

	render() {
		return (
			<div className={`c-video ${this.state.active ? 'is-active' : ''}`} onClick={() => this.initVideo()}>
				<div id={`video-${this.props.id}`}></div>
				<Icon use="play" className="c-video__play" />
				<img alt="Video" src={`https://img.youtube.com/vi/${this.props.id}/maxresdefault.jpg`} />
			</div>
		);
	}
}

export default Video;
