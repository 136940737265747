import 'react-app-polyfill/ie11';
import React from 'react'
import { render } from 'react-dom'
import * as serviceWorker from './serviceWorkerConfig';
import App from './App'
import './assets/sass/main.scss'
import { strIncludes, arrIncludes, startsWith } from './modules/polyfills';
strIncludes();
arrIncludes();
startsWith();

render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
