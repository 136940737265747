import React from 'react';
import Icon from './Icon';
import PropTypes from 'prop-types';

const PresentationRow = (props) => {
	return (
		<div className="o-layout__item u-width-12/12 c-presentation-row">
			<div className="o-box o-box--spacing-small">
				<div className="o-layout o-layout--middle o-layout--center">
					<div className="o-layout__item c-type-echo c-type--bold c-type-body-font u-width-12/12 u-width-grow@md u-margin-bottom-small u-margin-bottom-none@md u-align-center u-align-left@md">{props.title}</div>
					{props.ppt ? (
						<div className="o-layout__item u-width-shrink">
							<a href={`/downloads/presentations/${props.ppt}`}><Icon use="file-ppt" className="c-icon--file-ppt" /></a>
						</div>
					) : null}
					{props.pdf ? (
						<div className="o-layout__item u-width-shrink">
							<a href={`/downloads/presentations/${props.pdf}`} target="_blank"><Icon use="file-pdf" className="c-icon--file-pdf" /></a>
						</div>
					) : null}
					{props.video ? (
						<div className="o-layout__item u-width-shrink">
							<a href={`${props.video}`} target="_blank"><Icon use="video" className="c-icon--video" /></a>
						</div>
					) : null}
				</div>
			</div>
		</div>           
	)
}

PresentationRow.propTypes = {
	title: PropTypes.string,
	ppt: PropTypes.string,
	pdf: PropTypes.string,
	video: PropTypes.string
}

export default PresentationRow;