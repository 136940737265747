import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

class InfoRowUrl extends React.Component {
	static propTypes = {
		name: PropTypes.string,
		url: PropTypes.string,
		tooltip: PropTypes.func,
		handleClick: PropTypes.func
	};

	render() {
		return (
			<div className="c-info-row">
				<div className="c-info-row__label">
					{this.props.tooltip ? (
						<React.Fragment>
							<div className="u-margin-right-tiny">{this.props.name}</div>
							{this.props.tooltip()}
						</React.Fragment>
					) : this.props.name}
				</div>
				<div className="c-info-row__wrapper">
					<Button className="c-info-row__button" handleClick={this.props.handleClick}>Open</Button>
				</div>
			</div>
		);
	}
}

export default InfoRowUrl;