import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';
import Switch from './Switch';
import Form from './Form';
import Tooltip from './Tooltip';

import { updateDealerCalculator } from './../api/dealerWrite';

class DealerCalculatorSettings extends React.Component {
	static propTypes = {
		settings: PropTypes.oneOfType([
			PropTypes.object,
			PropTypes.array
		]),
	};

	constructor(props) {
		super(props)
		this.state = {
			localSettings: JSON.parse(JSON.stringify(this.props.settings))
		}
	}

	typeTooltip = () => {
		return (
			<React.Fragment>
				<strong>APR:</strong> Annual Percentage Rate<br />
				<strong>PAF:</strong> Per Annum Flat<br />
				<strong>REF:</strong> Commission Reference<br />
			</React.Fragment>
		)
	}

	updateState = (prefix, state, value) => {
		const newLocalSettings = {...this.state.localSettings}
		newLocalSettings[prefix][state] = value;
		this.setState({
			localSettings: newLocalSettings
		})
	}

	submit = () => {
		this.props.handleSave(this.state.localSettings);
		updateDealerCalculator(this.props.credentials, this.props.match.params.id, this.state.localSettings.rb, this.state.localSettings.hp, this.state.localSettings.nf)
		this.props.handleClose();
	}

	renderBlock = (name, prefix) => {
		return (
			<div className="u-margin-bottom-regular u-margin-bottom-large@md u-margin-bottom-disable-last-child">
				<h2 className="c-type-charlie u-margin-bottom-small">{name}</h2>
				<div className="o-box o-box--spacing-small c-box c-box--rounded">
					<div className="o-layout">
						<div className="o-layout__item u-width-6/12 u-width-4/12@sm3 u-width-3/12@md u-width-auto@lg2 u-margin-bottom-small u-margin-bottom-none@lg2">
							<Input
								name={`${prefix}-type`}
								type="select"
								label="Type"
								tooltip={() => <Tooltip message={this.typeTooltip()} small />}
								value={this.state.localSettings[prefix].type}
								handleChange={(value) => this.updateState(prefix, 'type', value)}
							>
								<option value="apr">APR</option>
								<option value="paf">PAF</option>
								<option value="ref">REF</option>
							</Input>
						</div>
						<div className="o-layout__item u-width-6/12 u-width-4/12@sm3 u-width-3/12@md u-width-auto@lg2 u-margin-bottom-small u-margin-bottom-none@lg2">
							<Input
								name={`${prefix}-value`}
								type="select"
								label="Value"
								value={this.state.localSettings[prefix].val}
								handleChange={(value) => this.updateState(prefix, 'val', value)}
							>
								<option>5.9</option>
								<option>6.4</option>
								<option>6.9</option>
								<option>7.4</option>
								<option>7.9</option>
								<option>8.4</option>
								<option>8.9</option>
								<option>9.4</option>
								<option>9.9</option>
								<option>10.4</option>
								<option>10.9</option>
								<option>11.4</option>
								<option>11.9</option>
								<option>12.4</option>
								<option>12.9</option>
								<option>13.4</option>
								<option>13.9</option>
								<option>14.4</option>
								<option>14.9</option>
								<option>15.4</option>
								<option>15.9</option>
								<option>16.4</option>
								<option>16.9</option>
								<option>17.4</option>
								<option>17.9</option>
								<option>18.4</option>
								<option>18.9</option>
							</Input>
						</div>
						<div className="o-layout__item u-width-6/12 u-width-4/12@sm3 u-width-3/12@md u-width-auto@lg2 u-margin-bottom-small u-margin-bottom-none@lg2">
							<Input
								name={`${prefix}-term`}
								type="select"
								label="Term"
								value={this.state.localSettings[prefix].term}
								handleChange={(value) => this.updateState(prefix, 'term', value)}
							>
								<option value="12">12 months</option>
								<option value="18">18 months</option>
								<option value="24">24 months</option>
								<option value="30">30 months</option>
								<option value="36">36 months</option>
								<option value="42">42 months</option>
								<option value="48">48 months</option>
								<option value="54">54 months</option>
								<option value="60">60 months</option>
							</Input>
						</div>
						<div className="o-layout__item u-width-6/12 u-width-4/12@sm3 u-width-3/12@md u-width-auto@lg2 u-margin-bottom-small u-margin-bottom-none@lg2">
							<Input
								name={`${prefix}-min-flat`}
								type="number"
								label="Min Flat"
								value={this.state.localSettings[prefix].min_flat}
								handleChange={(value) => this.updateState(prefix, 'min_flat', value)}
							 />
						</div>
						<div className="o-layout__item u-width-6/12 u-width-4/12@sm3 u-width-3/12@md u-width-auto@lg2 u-margin-bottom-small u-margin-bottom-none@lg2">
							<Input
								name={`${prefix}-min-apr`}
								type="number"
								label="Min APR"
								value={this.state.localSettings[prefix].min_apr}
								handleChange={(value) => this.updateState(prefix, 'min_apr', value)}
							/>
						</div>
						<div className="o-layout__item u-width-6/12 u-width-4/12@sm3 u-width-3/12@md u-width-auto@lg2 u-margin-bottom-small u-margin-bottom-none@lg2">
							<Input
								name={`${prefix}-max-flat`}
								type="number"
								label="Max Flat"
								value={this.state.localSettings[prefix].max_flat}
								handleChange={(value) => this.updateState(prefix, 'max_flat', value)}
							/>
						</div>
						<div className="o-layout__item u-width-6/12 u-width-4/12@sm3 u-width-3/12@md u-width-auto@lg2 u-margin-bottom-small u-margin-bottom-none@lg2">
							<Input
								name={`${prefix}-max-apr`}
								type="number"
								label="Max APR"
								value={this.state.localSettings[prefix].max_apr}
								handleChange={(value) => this.updateState(prefix, 'max_apr', value)}
							/>
						</div>
						<div className="o-layout__item u-width-6/12 u-width-4/12@sm3 u-width-3/12@md u-width-auto@lg2 u-margin-bottom-small u-margin-bottom-none@lg2">
							<Switch
								name={`${prefix}-lock`}
								label="Lock"
								tooltip={() => <Tooltip message="Restrict settings to their default values, as specified here by you." align="right" small />}
								active={parseInt(this.state.localSettings[prefix].locked) ? true : false}
								handleClick={() => this.updateState(prefix, 'locked', this.state.localSettings[prefix].locked === '1' ? '0' : '1')}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	} 

	render() {
		return (
			<div>
				<Form
					handleCancel={this.props.handleClose}
					handleSubmit={this.submit}
				>
					{this.renderBlock('Residual Backed', 'rb')}
					{this.renderBlock('Hire Purchase', 'hp')}
					{this.renderBlock('No Fees', 'nf')}
				</Form>
			</div>
		);
	}
}

export default DealerCalculatorSettings;