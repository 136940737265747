import React from 'react';
import PropTypes from 'prop-types';

import Input from './Input';
import Icon from './Icon';

class Calendar extends React.Component {
	static propTypes = {
		input: PropTypes.bool,
		date: PropTypes.object,
		onSelectDate: PropTypes.func,
		onSelectTime: PropTypes.func,
		active: PropTypes.bool,
		float: PropTypes.bool,
		showTime: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {
			date: props.date ? props.date : new Date(),
			currentDate: new Date(),
			months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			selectedDay: props.date ? props.date.getDate().toString() : null,
			selectedMonth: props.date ? props.date.getMonth() : null,
			selectedYear: props.date ? props.date.getFullYear() : null,
			selectedDate: null,
			selectedHours: '0',
			selectedMinutes: '0'
		}
		if(!props.date) {
			this.state.currentMonth = this.state.months[this.state.currentDate.getMonth()];
			this.state.currentYear = this.state.currentDate.getFullYear();
		}
		// Create DOM ref for calendar as we'll be editing it alot
		this.calendar = React.createRef();
	}

	handleKeyPress = (e, func) => {
		if(e.which === 13) {
			func(e);
		}
	}

	isLeapYear = (year) => {
		if(year%400===0) {
			return true;
		} else if(year%100===0) {
			return false;
		} else if(year%4===0) {
			return true;
		} else {
			return false;
		}
	}

	getDayFirstDate = (date) => {
		const tempd = new Date();
		tempd.setFullYear(date.getFullYear());
		tempd.setMonth(date.getMonth());
		tempd.setDate(1);
		tempd.setHours(0);
		tempd.setMinutes(0);
		tempd.setSeconds(0);
		// getDay() by default thinks Sunday is the start of the week probably because of the yanks.
		// This is of course nonsense, so we adjust it so Monday is returned whenever Sunday would have been
		// God save the queen etc al
		return tempd.getDay() ? tempd.getDay() - 1 : 7 - 1;
	}

	howManyDays = (date) => {
		const month = date.getMonth()+1 ;
		if(month===1||month===3||month===5||month===7||month===8||month===10||month===12) return 31;
		if(month===2) {
			if(this.isLeapYear(date.getFullYear())) {
				return 29;
			} else {
				return 28;
			}
		}
		return 30;
	}

	updateDate = (date, direction) => {
		const m = date.getMonth();
		if(direction === 'next') {
			if(m+1>11) {
				date.setFullYear(date.getFullYear()+1);
				date.setMonth(0);
			} else {
				date.setMonth(m+1);
			}

		}
		else {
			if(m-1<0) {
				date.setFullYear(date.getFullYear()-1);
				date.setMonth(11);
			} else {
				date.setMonth(m-1);
			}
		}
		this.setState({
			date: date
		})
	}

	generateCalendar = (date) => {
		const calendar = this.calendar.current;
		const days = this.howManyDays(date);
		const shift = this.getDayFirstDate(date);
		const wrapper = calendar.querySelector('.c-calendar__main tbody');
		const cells = wrapper.querySelectorAll('td');

		for(let i = 0; i < cells.length; i++) {
			cells[i].removeAttribute('data-date');
			cells[i].classList.remove('is-active');
		}

		for(let i = 0; i < days; i++) {
			const posi_row = Math.floor((i+shift)/7);
			const posi_col = Math.floor((i+shift)%7);
			wrapper.querySelector('tr[data-row="' + posi_row + '"] td[data-col="' + posi_col + '"]').setAttribute('data-date', i+1);
		}

		let hitMonth = false;
		let afterInt = 1;
		const prevMonthCells = [];
		for(let i = 0; i < cells.length; i++) {
			cells[i].classList.remove('c-calendar__cell--external-day', 'c-calendar__cell--today', 'c-calendar__cell--external-day-before', 'c-calendar__cell--external-day-after');
			if(cells[i].getAttribute('data-date')) {
				hitMonth = true;
			}
			if(!hitMonth) {
				prevMonthCells.push(cells[i]);
			}
			else {
				if(!cells[i].getAttribute('data-date')) {
					cells[i].setAttribute('data-date', afterInt);
					cells[i].classList.add('c-calendar__cell--external-day');
					cells[i].classList.add('c-calendar__cell--external-day-after');
					afterInt++;
				}
			}
			// Mark todays date
			if(
				this.state.currentMonth === this.state.months[this.state.currentDate.getMonth()] &&
				this.state.currentYear === this.state.currentDate.getFullYear() &&
				parseInt(cells[i].getAttribute('data-date')) === this.state.currentDate.getDate() &&
				!cells[i].classList.contains('c-calendar__cell--external-day')
			) {
				cells[i].classList.add('c-calendar__cell--today');
			}
		}
		const prevMonthLastDay = new Date(date.getYear(), date.getMonth(), 0).getDate();
		for(let i = 0; i < prevMonthCells.length; i++) {
			prevMonthCells[i].setAttribute('data-date', (prevMonthLastDay - (prevMonthCells.length - 1)) + i);
			prevMonthCells[i].classList.add('c-calendar__cell--external-day');
			cells[i].classList.add('c-calendar__cell--external-day-before');
		}

		const selectedDay = this.state.selectedDay;
		const selectedMonth = this.state.selectedMonth
		const selectedYear = this.state.selectedYear;

		const selectedMonthDate = new Date(selectedMonth + ' ' + selectedYear).getMonth();

		const currentMonth = this.state.currentMonth;
		const currentYear = this.state.currentYear;
		const currentMonthDate = new Date(currentMonth + ' ' + currentYear).getMonth();

		for(var i = 0; i < cells.length; i++) {
			if(selectedDay && selectedMonth) {
				if(
					cells[i].classList.contains('c-calendar__cell--external-day-before') &&
					selectedMonthDate < currentMonthDate &&
					Math.abs(selectedMonthDate - currentMonthDate) === 1 && selectedDay === cells[i].getAttribute('data-date') &&
					selectedYear === currentYear
				) {
					cells[i].classList.add('is-active');
					break;
				}
				else if(
					cells[i].classList.contains('c-calendar__cell--external-day-after') &&
					selectedMonthDate > currentMonthDate &&
					Math.abs(selectedMonthDate - currentMonthDate) === 1 &&
					selectedDay === cells[i].getAttribute('data-date') &&
					selectedYear === currentYear
				) {
					cells[i].classList.add('is-active');
					break;
				}
				else if(
					selectedMonth === currentMonth &&
					selectedDay === cells[i].getAttribute('data-date') &&
					selectedYear === currentYear &&
					!cells[i].classList.contains('c-calendar__cell--external-day')
				) {
					cells[i].classList.add('is-active');
					break;
				}
			}
		}
	}

	sendDate = (time) => {
		const newDate = new Date(this.state.selectedDay + ' ' + this.state.selectedMonth + ' ' + this.state.selectedYear);
		newDate.setHours(this.state.selectedHours.length === 1 ? '0' + this.state.selectedHours : this.state.selectedHours, this.state.selectedMinutes.length === 1 ? '0' + this.state.selectedMinutes : this.state.selectedMinutes, '00');
		if(!time) {
			this.props.onSelectDate(newDate);
		}
		else {
			this.props.onSelectTime(newDate);
		}
	}

	changeMonth = (direction) => {
		if(direction === 'next') {
			this.updateDate(this.state.date, 'next');
		}
		else {
			this.updateDate(this.state.date, 'prev');
		}
		this.setState({
			currentMonth: this.state.months[this.state.date.getMonth()],
			currentYear: this.state.date.getFullYear()
		}, () => {
			this.generateCalendar(this.state.date);
		});
	}

	selectDate = (chosenCell, cells) => {
		for(let p = 0; p < cells.length; p++) {
			if(cells[p].classList.contains('is-active')) {
				cells[p].classList.remove('is-active');
			}
		}
		chosenCell.classList.add('is-active');
		if(chosenCell.classList.contains('c-calendar__cell--external-day')) {
			const tempDate = new Date(`1 ${this.state.currentMonth} ${this.state.currentYear}`);
			if(chosenCell.classList.contains('c-calendar__cell--external-day-after')) {
				this.updateDate(tempDate, 'next');
			}
			else if(chosenCell.classList.contains('c-calendar__cell--external-day-before')) {
				this.updateDate(tempDate, 'prev');
			}
			this.setState({
				selectedMonth: this.state.months[tempDate.getMonth()],
				selectedYear: tempDate.getFullYear(),
				selectedDay: chosenCell.getAttribute('data-date'),
				//selectedDate: new Date(`${tempDate.getFullYear()}-${tempDate.getMonth() + 1}-${chosenCell.getAttribute('data-date')}T${this.state.selectedHours.length === 1 ? '0' + this.state.selectedHours : this.state.selectedHours}:${this.state.selectedMinutes.length === 1 ? '0' + this.state.selectedMinutes : this.state.selectedMinutes}:00`)
			}, () => {
				if(this.props.onSelectDate) {
					this.sendDate();
				}
			})
		}
		else {
			this.setState({
				selectedMonth: this.state.currentMonth,
				selectedYear: this.state.currentYear,
				selectedDay: chosenCell.getAttribute('data-date'),
				//selectedDate: new Date(`${this.state.currentYear}-${this.state.date.getMonth}-${chosenCell.getAttribute('data-date')}T${this.state.selectedHours.length === 1 ? '0' + this.state.selectedHours : this.state.selectedHours}:${this.state.selectedMinutes.length === 1 ? '0' + this.state.selectedMinutes : this.state.selectedMinutes}:00`)
			}, () => {
				if(this.props.onSelectDate) {
					this.sendDate();
				}
			})
		}
	}

	componentDidMount() {
		this.generateCalendar(this.props.date || this.state.date);
	}

	componentDidUpdate(prevProps) {
		if(prevProps.date !== this.props.date && this.props.date) {
			this.setState({
				date: this.props.date,
				selectedDay: this.props.date.getDate().toString(),
				selectedMonth: this.state.months[this.props.date.getMonth()],
				selectedYear: this.props.date.getFullYear(),
				currentMonth: this.state.months[this.props.date.getMonth()],
				currentYear: this.props.date.getFullYear(),
			}, () => {
				this.generateCalendar(this.state.date);
			})
		}
		if(prevProps.active && !this.props.active) {
			if(
				this.state.currentMonth !== this.state.selectedMonth ||
				this.state.currentYear !== this.state.selectedYear
			) {
				if(this.state.selectedMonth && this.state.selectedYear) {
					this.setState({
						currentMonth: this.state.selectedMonth,
						currentYear: this.state.selectedYear
					}, () => {
						this.generateCalendar(this.state.date);
					})
				}
			}
		}
	}

	updateTime = (key, value) => {
		this.setState({
			[key] : value
		}, () => {
			this.sendDate(true);
		});
	}



	render() {
		return (
			<div className={`c-calendar c-calendar--small ${this.props.input && this.props.active ? 'is-active' : ''} ${this.props.input ? 'c-input__calendar' : ''} ${this.props.input && this.props.float ? 'c-input__calendar--float' : ''} ${this.props.showTime ? 'c-calendar--time' : ''}`} ref={this.calendar}>
				<div className="o-box o-box--spacing-tiny o-box--spacing-small@sm2">
					<div className="o-layout o-layout--middle o-layout--flush c-calendar__buttons">
						<div className="o-layout__item u-width-shrink c-calendar__button c-calendar__button--prev" tabIndex="0" onClick={() => this.changeMonth('prev')} onKeyPress={(e) => this.handleKeyPress(e, () => this.changeMonth('prev'))}>
							<Icon use="arrow" />
						</div>
						<div className="o-layout__item c-calendar__month-label">
							<div className="c-calendar__label">
								<span className="c-calendar__label-day"></span>
								<span className="c-calendar__label-month">{this.state.currentMonth} </span>
								<span className="c-calendar__label-year">{this.state.currentYear}</span>
							</div>
						</div>
						<div className="o-layout__item u-width-shrink c-calendar__button c-calendar__button--next" tabIndex="0" onClick={() => this.changeMonth('next')} onKeyPress={(e) => this.handleKeyPress(e, () => this.changeMonth('next'))}>
							<Icon use="arrow" />
						</div>
					</div>
				</div>

				{this.props.showTime ? (
					<div className="o-box o-box--spacing-tiny o-box--spacing-small@sm2 c-box c-box--bordered-vertical">
						<div className="o-layout">
							<div className="o-layout__item">
								<Input label="Hours" type="select" name="hours" leftLabel handleChange={(value) => this.updateTime('selectedHours', value)}>
									{Array.from(Array(24).keys()).map(i => <option value={i} key={i}>{i}</option>)}
								</Input>
							</div>
							<div className="o-layout__item">
								<Input label="Minutes" type="select" name="minutes" leftLabel handleChange={(value) => this.updateTime('selectedMinutes', value)}>
									{Array.from(Array(60).keys()).map(i => <option value={i} key={i}>{i}</option>)}
								</Input>
							</div>
						</div>
					</div>
				) : null}

				<div className="o-box o-box--spacing-tiny o-box--spacing-small@sm2">
					<table className="c-calendar__main">
						<thead>
							<tr>
								<th>Mon</th>
								<th>Tue</th>
								<th>Wed</th>
								<th>Thu</th>
								<th>Fri</th>
								<th>Sat</th>
								<th>Sun</th>
							</tr>
						</thead>
						<tbody>
							<tr data-row="0">
								<td
									className="c-calendar__cell"
									data-col="0"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="1"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="2"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="3"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="4"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="5"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="6"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
							</tr>
							<tr data-row="1">
								<td
									className="c-calendar__cell"
									data-col="0"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="1"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="2"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="3"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="4"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="5"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="6"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
							</tr>
							<tr data-row="2">
								<td
									className="c-calendar__cell"
									data-col="0"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="1"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="2"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="3"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="4"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="5"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="6"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
							</tr>
							<tr data-row="3">
								<td
									className="c-calendar__cell"
									data-col="0"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="1"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="2"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="3"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="4"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="5"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="6"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
							</tr>
							<tr data-row="4">
								<td
									className="c-calendar__cell"
									data-col="0"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="1"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="2"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="3"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="4"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="5"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="6"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
							</tr>
							<tr data-row="5">
								<td
									className="c-calendar__cell"
									data-col="0"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="1"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="2"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="3"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="4"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="5"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
								<td
									className="c-calendar__cell"
									data-col="6"
									tabIndex="0"
									onClick={(e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td'))}
									onKeyPress={(e) => this.handleKeyPress(e, (e) => this.selectDate(e.target, this.calendar.current.querySelectorAll('.c-calendar__main td')))}
								></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default Calendar;