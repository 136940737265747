import configData from '../settings.js';

export function onlineStatus() {
	return new Promise((resolve, reject) => {
		var request = new XMLHttpRequest();
		request.onreadystatechange = function () {
			if(request.readyState === 4) {
				if(request.status === 200) {
					resolve();
				}
				else {
					reject();
				}
			}
		}
		request.open('get', configData.EVOLUTION_MAIN_ENDPOINT + '/activity/f.php?f=ajax_available', true);
		request.send();
	});
}