import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import Icon from './Icon';

class Button extends React.Component {

	static propTypes = {
		secondary: PropTypes.bool,
		icon: PropTypes.string,
		iconSize: PropTypes.string,
		iconSide: PropTypes.string,
		className: PropTypes.string,
		tabIndex: PropTypes.string,
		to: PropTypes.string,
		slim: PropTypes.string,
		handleClick: PropTypes.func,
		state: PropTypes.string,
		dummy: PropTypes.bool
	};

	buttonIcon = () => {
		const iconSize = this.props.iconSize ? `c-button__icon--${this.props.iconSize}` :  '';
		if(this.props.icon) {
			return (
				<Icon className={`c-button__icon ${iconSize} ${this.props.iconSide !== 'left' && !this.props.slim ? 'c-button__icon--right' : ''} ${this.props.iconSide === 'left' && !this.props.slim ? 'c-button__icon--left' : ''}`} use={this.props.icon} />
			)
		}
	}

	handleClick = (e) => {
		if(this.props.dummy) {
			e.preventDefault();
		}
		if(this.props.handleClick) {
			this.props.handleClick();
		}
	}

	buttonClasses = () => {
		let classes = `c-button ${this.props.className}`;
		if(this.props.secondary) {
			classes += ' c-button--secondary';
		}
		if(this.props.slim) {
			classes += ' c-button--slim';
		}
		if(this.props.state) {
			classes += ` is-${this.props.state}`;
		}
		return classes;
	}

	buttonContents = () => {
		return (
			<button className={this.buttonClasses()} tabIndex={this.props.tabIndex} onClick={(e) => this.handleClick(e)}>
				{this.props.iconSide === 'left' ? this.buttonIcon() : null}
				<span className="c-button__text">{this.props.children}</span>
				{this.props.state === 'loading' ? (
					<div className="o-cover c-button__cover c-button__cover--loader">
						<Icon use="loading" className="c-button__icon c-button__icon--huge" />
					</div>
				) : null}
				{this.props.iconSide !== 'left' ? this.buttonIcon() : null}
			</button>
		)
	}

	render() {
		if(this.props.to) {
			return (
				<Link to={this.props.to}>{this.buttonContents()}</Link>
			)
		}
		else {
			return this.buttonContents();
		}
	}
}

export default Button;