import React from 'react';
import PropTypes from 'prop-types';

const Badge = (props) => {
	return (
		<div className={`c-badge ${props.className} ${props.round ? 'c-badge--round' : ''}`}>
			{props.children}
		</div>           
	)
}

Badge.propTypes = {
	round: PropTypes.bool,
	className: PropTypes.string
}

export default Badge;