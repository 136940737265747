import { parseLocalStorage, storeLocalStorage, removeLocalStorage, searchLocalStorage } from '../modules/handleStorage';
import post from '../modules/post';

import configData from '../settings.js';

export function syncSetup(key, postData, label, parameters) {
	let postDataWithParams;
	if(parameters) {
		postDataWithParams = {...postData};
		postDataWithParams['parameters'] = parameters;
	}
	const pendingItems = parseLocalStorage(key) || [];
	pendingItems.push(postDataWithParams || postData);
	storeLocalStorage(key, pendingItems);
	console.log(`🚨 Storing ${label} data which will be synced once connection is restored.`);
}

export function syncController(credentials, online) {
	return new Promise((resolve, reject) => {
		// Do we even need to sync?
		let shouldWeSync = searchLocalStorage('offline');
		// eslint-disable-next-line
		if(!online && online !== null || !shouldWeSync) {
			console.log('😵 No sync required at this time.')
			return reject();
		}

		const sync = (postData, func, credentials) => {
			return new Promise((resolve, reject) => {
				if(credentials.userId === postData.user_id) {
					const postDataToSend = {...postData};
					post(configData.EVOLUTION_MAIN_ENDPOINT + `/activity/f.php?f=${func}`, postDataToSend)
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					})
				}
			});
		}

		const syncDealers = (credentials) => {
			return new Promise((resolve, reject) => {
				const offlineDealers = parseLocalStorage('offlineDealers');
				const mappings = {}
				if(offlineDealers) {
					console.log('🤘 Syncing offline dealers')
					for(let i = 0; i < offlineDealers.length; i++) {
						sync(offlineDealers[i], 'newdealer', credentials)
						.then((response) => {
							mappings[offlineDealers[i].temp_ice_id] = response;
							// If we've finished last dealer, resolve
							if(offlineDealers.length - i === 1) {
								// If last item, its ok to delete the cached data
								console.log("💀 Deleted 'offlineDealers' as no longer needed")
								removeLocalStorage('offlineDealers');
								resolve(mappings);
							}
						})
						.catch((error) => {
							console.log(error);
							console.log('👎 Could not sync dealers at this time');
							resolve();
						})
					}
				}
				// Skip sync if no offline dealers to add
				else {
					console.log('🤷‍♂️ No offline dealers to sync');
					resolve();
				}
			})
		}

		const syncDealerContacts = (credentials, dealerMappings) => {
			return new Promise((resolve, reject) => {
				const offlineDealerContacts = parseLocalStorage('offlineDealerContacts');
				const mappings = {}
				if(offlineDealerContacts) {
					console.log('🤘 Syncing offline dealer contacts')
					for(let i = 0; i < offlineDealerContacts.length; i++) {
						// Use mappings to fill in correct ICE_IDs if the dealer was offline
						// If the dealer was added whilst online, it will already have the correct ICE_ID
						if(dealerMappings) {
							offlineDealerContacts[i].dealer_id = dealerMappings[offlineDealerContacts[i].dealer_id];
						}
						// Post
						sync(offlineDealerContacts[i], 'contactadd', credentials)
						.then((response) => {
							mappings[offlineDealerContacts[i].temp_contact_id] = response;
							// If we've finished last dealer contact, resolve
							if(offlineDealerContacts.length - i === 1) {
								// If last item, its ok to delete the cached data
								console.log("💀 Deleted 'offlineDealerContacts' as no longer needed")
								removeLocalStorage('offlineDealerContacts');
								resolve({dealerContactMappings: mappings, dealerMappings});
							}
						})
						.catch((error) => {
							console.log(error);
							console.log('👎 Could not sync dealer contacts at this time');
							resolve();
						})
					}
				}
				// Skip sync if no offline dealer contacts to add
				else {
					console.log('🤷‍♂️ No offline dealer contacts to sync')
					resolve(dealerMappings);
				}
			})
		}

		const syncDealerContactChanges = (credentials, contactMappings) => {
			return new Promise((resolve, reject) => {
				const offlineDealerContactEdits = parseLocalStorage('offlineDealerContactEdits');
				if(offlineDealerContactEdits) {
					console.log('🤘 Syncing offline dealer contact changes')
					for(let i = 0; i < offlineDealerContactEdits.length; i++) {
						// Use mappings to replace temp-contact-ids with real ones, if a mapping entry exists
						if(contactMappings && contactMappings.dealerContactMappings[offlineDealerContactEdits[i].id]) {
							offlineDealerContactEdits[i].id = contactMappings.dealerContactMappings[offlineDealerContactEdits[i].id];
						}
						// Post
						sync(offlineDealerContactEdits[i], 'contactedit', credentials)
						.then(() => {
							// If we've finished last item, resolve
							if(offlineDealerContactEdits.length - i === 1) {
								// If last item, its ok to delete the cached data
								console.log("💀 Deleted 'offlineDealerContactEdits' as no longer needed")
								removeLocalStorage('offlineDealerContactEdits');
								resolve();
							}
						})
						.catch((error) => {
							console.log(error);
							console.log('👎 Could not sync dealer contact changes at this time');
							resolve();
						})
					}
				}
				// Skip sync if no offline dealer contact edits to add
				else {
					console.log('🤷‍♂️ No offline dealer contact details to sync')
					resolve();
				}
			});
		}

		const syncDealerDeletions = (credentials, contactMappings) => {
			return new Promise((resolve, reject) => {
				const offlineDeletedDealerContacts = parseLocalStorage('offlineDeletedDealerContacts');
				if(offlineDeletedDealerContacts) {
					console.log('🤘 Syncing offline dealer contact deletions');
					for(let i = 0; i < offlineDeletedDealerContacts.length; i++) {
						// Use mappings to replace temp-contact-ids with real ones
						if(contactMappings) {
							const id = offlineDeletedDealerContacts[i].parameters.split('id=')[1];
							offlineDeletedDealerContacts[i].parameters = `&id=${contactMappings.dealerContactMappings[id]}`;
						}
						// Post
						sync(offlineDeletedDealerContacts[i], `contactdelete${offlineDeletedDealerContacts[i].parameters}`, credentials)
						.then(() => {
							// If we've finished last item, resolve
							if(offlineDeletedDealerContacts.length - i === 1) {
								// If last item, its ok to delete the cached data
								console.log("💀 Deleted 'offlineDeletedDealerContacts' as no longer needed");
								removeLocalStorage('offlineDeletedDealerContacts');
								resolve();
							}
						})
						.catch((error) => {
							console.log(error);
							console.log('👎 Could not sync dealer contact deletions at this time');
							resolve();
						})
					}
				}
				// Skip sync if no offline dealer contacts to delete
				else {
					console.log('🤷‍♂️ No offline dealer contact deletions to sync');
					resolve();
				}
			});
		}

		const syncDealerActivity = (credentials, dealerMappings, contactMappings) => {
			return new Promise((resolve, reject) => {
				const offlineDealerActivity = parseLocalStorage('offlineDealerActivity');
				if(offlineDealerActivity) {
					console.log('🤘 Syncing dealer visits')
					for(let i = 0; i < offlineDealerActivity.length; i++) {
						// Replace temp dealer ICE_ID and Contact ID's with real values
						if(dealerMappings) {
							offlineDealerActivity[i].ice_id = dealerMappings[offlineDealerActivity[i].ice_id];
						}
						if(contactMappings && contactMappings[offlineDealerActivity[i].contact_id]) {
							offlineDealerActivity[i].contact_id = contactMappings[offlineDealerActivity[i].contact_id];
						}
						// Post
						sync(offlineDealerActivity[i], 'addcall', credentials)
						.then(() => {
							// If we've finished last item, resolve
							if(offlineDealerActivity.length - i === 1) {
								// If last item, its ok to delete the cached data
								console.log("💀 Deleted 'offlineDealerActivity' as no longer needed")
								removeLocalStorage('offlineDealerActivity');
								resolve();
							}
						})
						.catch((error) => {
							console.log(error);
							console.log('👎 Could not sync dealer visits at this time');
							resolve();
						})
					}
				}
				// Skip sync if no visits to add
				else {
					console.log('🤷‍♂️ No dealer visits to sync')
					resolve();
				}
			});
		}

		// Each phase returns a mapping and passes it along to the next
		syncDealers(credentials)
		.then((dealerMappings) => {
			// Sync Dealer Contacts
			syncDealerContacts(credentials, dealerMappings)
			.then((mappings) => {
				// Dealer Contact Details Change
				syncDealerContactChanges(credentials, mappings);
				// New Visit
				syncDealerActivity(credentials, mappings && mappings.dealerMappings ? mappings.dealerMappings : null, mappings && mappings.dealerContactMappings ? mappings.dealerContactMappings : null);
				// Delete Contact
				syncDealerDeletions(credentials, mappings);
				// Exit syncController
				// Pass mappings back as they'll be used for some final manipulations pre and post sync
				return resolve(mappings);
			})
		})
	});
}
